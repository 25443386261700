// 任务列表
<template>
  <div class="order">
    <div class="title">
      <span>任务列表</span>
    </div>
    <div class="content">
      <div class="c_items" v-for="(item, index) in orderInfo" :key="item.id">
        <div class="task_name">
          <span>{{ index + 1 }}、</span>
          <span>{{ item.task_name }}</span>
        </div>
        <div class="task_state">
          <div v-if="item.status == 0" class="fontColor_warning">进行中</div>
          <div v-else-if="item.status == 1" class="fontColor_danger">
            已终止
          </div>
          <div v-else-if="item.status == 2" class="fontColor_success">
            已完成
          </div>
          <div v-else-if="item.status == 4" class="fontColor_info">维护中</div>
        </div>
        <div class="btn">
          <el-button type="text" @click="goDetail(item.id)">查看详情</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentBrand: {
      type: Number,
    },
  },
  data() {
    return {
      orderInfo: [],
    };
  },
  mounted() {
    this.getData();
  },
  watch: {
    currentBrand: function () {
      this.getData();
    },
  },
  methods: {
    goDetail(task_id) {
      // this.$router.push({
      //     path: '/service_view/taskDetail',
      //     query: {
      //         task_id,
      //         currentBrand: this.currentBrand
      //     }
      // })
      this.$emit("orderDetail", {
        task_id,
        target: "taskDetail",
      });
    },
    getData() {
      const loading = this.$loading({
        target: ".order",
      });
      this.orderInfo = [];
      this.$api
        .taskSubList({ brand_id: this.currentBrand })
        .then((res) => {
          // console.log(res)
          loading.close();
          if (res.code == 0) {
            res.data.list.forEach((item) => {
              if (item.is_hide === "0") {
                this.orderInfo.push({
                  task_name: item.task_name,
                  id: item.id,
                  status: item.status,
                });
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
          loading.close();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .title {
    height: 40px;
    border-bottom: 1px solid #c5c5c9;
    padding: 0 20px;
    > span {
      line-height: 40px;
      font-size: 15px;
      font-weight: bold;
      padding-left: 10px;
      border-left: 4px solid #107cf6;
    }
  }

  .content {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 20px;
    // 自定义滚动条样式
    &::-webkit-scrollbar {
      width: 5px;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
    &:hover::-webkit-scrollbar-thumb {
      background: hsla(0, 0%, 53%, 0.4);
    }
    &:hover::-webkit-scrollbar-track {
      background: hsla(0, 0%, 53%, 0.1);
    }
    .c_items {
      padding: 5px 20px;
      display: flex;
      align-items: center;
      border-bottom: 1px dotted #ccc;
      &:hover {
        background: #f5f7fa;
      }

      .task_name {
        flex: 1;
        padding: 0 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .task_state,
      .btn {
        width: 60px;
        text-align: center;
      }
      .btn {
        text-align: right;
      }
    }
  }
}
</style>
