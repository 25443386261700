// 品牌备注
<template>
  <div class="remarks">
      <div class="title_box">
        <div class="title">
            <span>品牌备注</span>
        </div>
        <div class="operation">
            <input type="text" class="add_input" v-model="remarks" autocomplete="off" placeholder="点此添加客户需求备注" @keyup.enter="addHandle">
        </div>
      </div>
      
      <div class="view_list">
        <el-timeline>
            <el-timeline-item
            v-for="(item, index) in remarksList"
            :key="index"
            color="#fff"
            icon="el-icon-paperclip"
            :timestamp="item.add_time"
            >
            {{item.text}}
            <div class="del_btn" @click="deleteHandle(item.id)"></div>
            </el-timeline-item>
        </el-timeline>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        currentBrand: { 
            type: Number 
        },
        updateSign: { 
            type: Boolean 
        }
    },
    data() {
        return {
            remarksList: [],
            remarks: ''
        }
    },
    mounted() {
        this.getData()
    },
    watch: {
        'currentBrand': function() {
            this.getData()
        }
    },
    methods: {
        addHandle() {
            if(!this.remarks) {
                this.$message.warning('内容为空')
                return
            }
            let paramObj = {
                brand_id: this.currentBrand,
                text: this.remarks
            }
            this.$api.addBrandRemarks(paramObj)
            .then(res => {
                // console.log(res)
                if(res.code == 0) {
                    this.getData()
                    this.remarks = ''
                }
            })
            .catch(error => {
                console.log(error)
                this.$message.error('添加失败')
            })
        },
        deleteHandle(id) {
            this.$confirm('确认删除吗?','提醒',{
                confirmButtonText: '确定',
                cancelButtonText: '我再想想',
            })
            .then(()=> {
                this.$api.deleteBrandRemarks({id})
                .then(res => {
                    // console.log(res)
                    this.getData()
                })
                .catch(error => {
                    console.log(error)
                    this.$message.error('删除失败')
                })
            })
            .catch(()=> {})
        },
        getData() {
            const loading = this.$loading({
                target: '.remarks',
            })
            let paramObj = {
                brand_id: this.currentBrand
            }
            this.$api.getBrandRemarks(paramObj)
            .then(res => {
                // console.log(res)
                loading.close()
                if(res.code == 0) {
                    this.remarksList = [...res.data.list].reverse()
                }
            })
            .catch(error => {
                console.log(error)
                loading.close()
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.remarks {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
    .title_box {
        display: flex;
        justify-content: space-between;
        height: 40px;
        padding: 0 20px;
        border-bottom: 1px solid #c5c5c9;

        .title {
            >span {
                line-height: 40px;
                font-size: 15px;
                font-weight: bold;
                padding-left: 10px;
                border-left: 4px solid #107CF6;
            }
            
        }
        .operation {
            text-align: right;
            flex: 1;

            .add_input {
                width: 80%;
                height: 100%;
                padding: 0 10px;
                font-size: 14px;
                color: #000;
                border: 1px solid #fff;
                transition: all .3s;
                border-radius: 5px;
                &:hover {
                    border: 1px solid #ccc;
                }
            }
        }
    }
    
    .view_list {
        padding: 20px;
        flex: 1;
        overflow-y: auto;
        // 自定义滚动条样式
        &::-webkit-scrollbar {
            width: 5px;
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: transparent;
        }
        &:hover::-webkit-scrollbar-thumb {
            background: hsla(0, 0%, 53%, 0.4);
        }
        &:hover::-webkit-scrollbar-track {
            background: hsla(0, 0%, 53%, 0.1);
        }

        // 时间线线条样式
        /deep/ .el-timeline-item__tail{
          border-left: 2px dotted #E4E7ED;
        }
        /deep/ .el-timeline-item__icon {
            color: #107CF6;
        }
        /deep/ .el-timeline-item__timestamp{
          // 更新时间样式
          font-size: 12px;
        }
        .el-timeline-item {
            position: relative;
            padding: 10px;
            padding-right: 20px;
            transition: all .2s;
            &:hover {
                .del_btn::after {
                    content: '×';
                }
                background: #f5f7fa;
            }
        }

        // 删除按钮
        .del_btn {
            font-size: 30px;
            color: #cc9a9a;
            cursor: pointer;
            position: absolute;
            top: 0;
            right: 0;
            transition: all 0.2s;

            &:hover {
                color: red;
            }
            // &::after {
            //     content: '×'
            // }
        }
    }
}
</style>