<!-- 投诉记录 -->
<template>
  <div class="complaints">
    <el-row :gutter="20" class="charts_area">
      <!-- 品牌投诉概况趋势图 -->
      <el-col :span="16">
        <div class="charts_items">
          <cpTrendChartVue :currentBrand="currentBrand" :updateChart="updateDataTag"></cpTrendChartVue>
        </div>
      </el-col>
      <!-- 投诉类型占比 -->
      <el-col :span="8">
        <div class="charts_items proport">
          <typeProportionVue :currentBrand="currentBrand" :updateChart="updateDataTag"></typeProportionVue>
        </div>
      </el-col>
    </el-row>
    
    <!-- 投诉信息列表 -->
    <div class="mainContentArea">
      <div class="queryBox">
          <div class="query_row">
            <div class="scan_box">
                <span class="sp_scan">平台：</span>
                <el-input 
                    @input="searchClick"
                    v-model="queryForm.plt" 
                    class="ip_scan"
                    size="mini"
                    clearable
                >
                </el-input>
            </div>
            <div class="scan_box">
                <span class="sp_scan">投诉时间：</span>
                <el-date-picker
                type="daterange"
                @change="searchClick"
                v-model="queryForm.complaint_time" 
                class="dateRange_scan"
                size="mini"
                range-separator="-"
                clearable
                unlink-panels
                value-format="yyyy-MM-dd"
                >
                </el-date-picker>
            </div>
            <div class="scan_box">
                <span class="sp_scan">知识产权类型：</span>
                <el-input 
                    @input="searchClick"
                    v-model="queryForm.intellectual_type" 
                    class="ip_scan"
                    size="mini"
                    clearable
                >
                </el-input>
            </div>
            <div class="scan_box">
                <span class="sp_scan">投诉单号：</span>
                <el-input 
                    @input="searchClick"
                    v-model="queryForm.complaint_id" 
                    class="ip_scan"
                    size="mini"
                    clearable
                >
                </el-input>
            </div>

        </div>
        <div class="btn_out_box">
            <el-button size="mini" type="primary" @click="resetSearch">重置查询</el-button>
            <el-button size="mini" @click="openAddPop(false)">添加</el-button>
            <div class="up_btn btn_items">
              <el-button size="mini">导入投诉信息</el-button>
              <input type="file" class="upload" ref="refUpload" @change="importHandle" />
            </div>
            <el-button @click="exportHandle" size="mini" class="btn_items">导出</el-button>
            <el-button type="text" @click="downLoadTam" class="btn_items">下载导入模板</el-button>
        </div>
      </div>

      <tablePageComp 
        :columnsList="columnsList" 
        :dataList="dataList" 
        :orderNumConfig="{}"
        :getDataConfig="dataQueryFunc()"
        :updateDataTag="updateDataTag"
        :totalCount="totalCount"
        >
        <el-table-column
        slot="info"
        align="center"
        label="投诉信息"
        width="220"
        >
            <template slot-scope="scope">
                <div class="cpt_info">
                  <p>{{scope.row.shopkeeper_nick}}</p>
                  <p>商品ID：<span>{{scope.row.goods_id}}</span></p>
                  <p>投诉时间：<span>{{scope.row.complaint_time}}</span></p>
                  <p>投诉链接：<i class="little_hands fontColor_theme" @click="open(scope.row)">{{scope.row.goods_url}}</i></p>
                </div>
            </template>
        </el-table-column>
        
        <!--   -->
        <el-table-column
        slot="handle"
        width="100"
        align="center"
        label="操作"
        >
            <template slot-scope="scope">
              <div class="edit_icon">
                <el-button 
                  type="text" 
                  icon="el-icon-edit-outline" 
                  @click="openAddPop(scope.row)" 
                  >
                </el-button>
                <el-button 
                  type="text" 
                  class="fontColor_danger"
                  icon="el-icon-delete" 
                  @click="delHandle(scope.row.id)" 
                  >
                </el-button>
              </div>
            </template>
        </el-table-column>

      </tablePageComp>
    </div>

    <el-dialog
    :title="isAdd ? '添加投诉记录' : '修改信息'"
    :visible.sync="editInfoVis"
    width="1000px"
    custom-class="popup_style"
    >
      <el-form
      :model="formData"
      :rules="rules"
      ref="editDom"
      label-width="110px"
      :inline="true"
      >
        <el-form-item label="平台" class="formItem_dialogForm" prop="plt">
          <el-input class="element_df_input" size="small" v-model="formData.plt"></el-input>
        </el-form-item>
        <el-form-item label="投诉链接" class="formItem_dialogForm" prop="goods_url">
          <el-input class="element_df_input" size="small" v-model="formData.goods_url"></el-input>
        </el-form-item>
        <el-form-item label="商品ID" class="formItem_dialogForm" prop="goods_id">
          <el-input class="element_df_input" size="small" v-model="formData.goods_id"></el-input>
        </el-form-item>
        <el-form-item label="店铺名称" class="formItem_dialogForm" prop="shop_name">
          <el-input class="element_df_input" size="small" v-model="formData.shop_name"></el-input>
        </el-form-item>
        <el-form-item label="掌柜名称" class="formItem_dialogForm" prop="shopkeeper_nick">
          <el-input class="element_df_input" size="small" v-model="formData.shopkeeper_nick"></el-input>
        </el-form-item>
        <el-form-item label="知识产权类型" class="formItem_dialogForm" prop="intellectual_type">
          <el-input class="element_df_input" size="small" v-model="formData.intellectual_type"></el-input>
        </el-form-item>
        <el-form-item label="投诉点" class="formItem_dialogForm">
          <el-input class="element_df_input" size="small" v-model="formData.complaint_point"></el-input>
        </el-form-item>
        <el-form-item label="投诉单号" class="formItem_dialogForm">
          <el-input class="element_df_input" size="small" v-model="formData.complaint_id"></el-input>
        </el-form-item>
        <el-form-item label="案件ID" class="formItem_dialogForm">
          <el-input class="element_df_input" size="small" v-model="formData.case_id"></el-input>
        </el-form-item>
        <el-form-item label="投诉进展" class="formItem_dialogForm">
          <el-input class="element_df_input" size="small" v-model="formData.complaint_progress"></el-input>
        </el-form-item>
        <el-form-item label="投诉结果" class="formItem_dialogForm">
          <el-select class="element_df_input" size="small" v-model="formData.complaint_result">
            <el-option v-for="(el, index) in $co.complaintResOptions" :key="index" :label="el.label" :value="el.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="链接状态" class="formItem_dialogForm">
          <el-input class="element_df_input" size="small" v-model="formData.url_type"></el-input>
        </el-form-item>
        <el-form-item label="通知进展" class="formItem_dialogForm">
          <el-input class="element_df_input" size="small" v-model="formData.notify_progress"></el-input>
        </el-form-item>
        <el-form-item label="售价" class="formItem_dialogForm">
          <el-input class="element_df_input" size="small" v-model="formData.price"></el-input>
        </el-form-item>
        <el-form-item label="销量" class="formItem_dialogForm">
          <el-input class="element_df_input" size="small" v-model="formData.sales"></el-input>
        </el-form-item>
        <el-form-item label="挽回销售额" class="formItem_dialogForm">
          <el-input class="element_df_input" size="small" v-model="formData.recover_sales"></el-input>
        </el-form-item>
        <el-form-item label="下一步方案" class="formItem_dialogForm">
          <el-input class="element_df_input" size="small" v-model="formData.step_plan"></el-input>
        </el-form-item>
        <el-form-item label="备注" class="formItem_dialogForm">
          <el-input class="element_df_input" size="small" v-model="formData.remarks" type="textarea" :rows="2"></el-input>
        </el-form-item>
        <el-form-item label="投诉时间" class="formItem_dialogForm">
          <el-date-picker
            v-model="formData.complaint_time"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            size="small"
            >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="通知时间" class="formItem_dialogForm">
          <el-date-picker
            v-model="formData.notification_time"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            size="small"
            >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="提供时间" class="formItem_dialogForm">
          <el-date-picker
            v-model="formData.provide_time"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            size="small"
            >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <div class="form_btn">
        <el-button type="primary" @click="submitForm" size="mini" :loading="btnLoading">提交</el-button>
        <el-button @click="resetForm()" size="mini" :loading="btnLoading">重置</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import cpTrendChartVue from "./components/cpTrendChart.vue"
import typeProportionVue from "./components/typeProportion.vue"
export default {
  name: 'complaintsRecord',
  components: {
    cpTrendChartVue,
    typeProportionVue
  },
  props:{
    // 当前品牌
    currentBrand: {
      type: Number
    },
  },
  watch:{
    'currentBrand':function(val) {
        this.updateDataTag = !this.updateDataTag
    }
  },
  data() {
    return {
      queryForm: {},
      dataList: [],
      columnsList: [
            {
                label:'操作',
                slotName:'handle'
            },
            {
                label:'平台',
                prop:'plt',
                width: 100
            },
            {
              label: "投诉信息",
              slotName: 'info'
            },
            // {
            //     label:'商品链接',
            //     prop:'goods_url'
            // },
            // {
            //     label:'商品ID',
            //     prop:'goods_id'
            // },
            // {
            //     label:'掌柜名称',
            //     prop:'shopkeeper_nick'
            // },
            // {
            //     label:'投诉时间',
            //     prop:'complaint_time'
            // },
            {
                label:'店铺名称',
                prop:'shop_name',
                width: 100
            },
            {
                label:'知识产权类型',
                prop:'intellectual_type',
                width: 150
            },
            {
                label:'投诉点',
                prop:'complaint_point',
                width: 100
            },
            
            {
                label:'投诉单号',
                prop:'complaint_id',
                width: 110
            },
            {
                label:'案件ID',
                prop:'case_id',
                width: 110
            },
            {
                label:'投诉进展',
                prop:'complaint_progress',
                width: 100
            },
            {
                label:'投诉结果',
                prop:'complaint_result',
                width: 100
            },
            {
                label:'链接状态',
                prop:'url_type',
                width: 100
            },
            {
                label:'通知时间',
                prop:'notification_time',
                width: 150
            },
            {
                label:'通知进展',
                prop:'notify_progress',
                width: 100
            },
            {
                label:'售价',
                prop:'price',
                width: 100
            },
            {
                label:'销量',
                prop:'sales',
                width: 100
            },
            {
                label:'挽回销售额',
                prop:'recover_sales',
                width: 100
            },
            {
                label:'下一步方案',
                prop:'step_plan',
                width: 100
            },
            {
                label:'提供时间',
                prop:'provide_time',
                width: 150
            },
            {
                label:'备注',
                prop:'remarks',
                width: 150
            },
            {
                label:'创建时间',
                prop:'add_time',
                width: '150'
            },
            
      ],
      updateDataTag: false,
      totalCount: 0,
      // 信息编辑框
      editInfoVis: false,
      isAdd: true,
      formData: {
        plt: '',
        goods_url: '',// 投诉链接
        goods_id: '',// 商品ID
        shop_name: '',// 店铺名称
        shopkeeper_nick: '',// 掌柜名称
        intellectual_type: '',// 知识产权类型
        complaint_point: '', // 投诉点
        complaint_time: '', // 投诉时间
        complaint_id: '', // 投诉单号
        case_id: '', // 案件ID
        complaint_progress: '', // 投诉进展
        complaint_result: '', // 投诉结果
        url_type: '', // 链接状态
        notification_time: '', // 通知时间
        notify_progress: '', // 通知进展
        price: '', // 售价
        sales: '', // 销量
        recover_sales: '', // 挽回销售额
        step_plan: '', // 下一步方案
        remarks: '', // 备注
        provide_time: '', // 提供时间
      },
      rules: {
        plt: [
          { required: true, message: '平台未填', trigger:'blur' }
        ],
        goods_url: [
          { required: true, message: '投诉链接未填', trigger:'blur' }
        ],
        goods_id: [
          { required: true, message: '商品ID未填', trigger:'blur' }
        ],
        shop_name: [
          { required: true, message: '店铺名称未填', trigger:'blur' }
        ],
        shopkeeper_nick: [
          { required: true, message: '掌柜名称未填', trigger:'blur' }
        ],
        intellectual_type: [
          { required: true, message: '知识产权类型未填', trigger:'blur' }
        ],
      },
      mId: '',// 待修改信息id
      btnLoading: false,
      queryForm: {
        plt: '',
        complaint_time: '',
        intellectual_type: '',
        complaint_id: ''
      }
    }
  },
  methods:{
    exportHandle() {
      const loading = this.$loading({
        lock: true,
        text: '正在导出数据',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let paramObj = {
        brand_id: this.currentBrand
      }
      Object.keys(this.queryForm).forEach(key => {
          if(this.queryForm[key]) {
            if(key == 'complaint_time') {
              paramObj[key] = this.queryForm[key].join(',')
            } else {
              paramObj[key] = this.queryForm[key]
            }
          }
      })

      this.$api.exportComplaint(paramObj)
      .then(res => {
        loading.close()
        if(res.code == 0) {
          window.location.href = res.data.url
        } else {
          this.$message.warning(res.msg)
        }
      })
      .catch(error => {
        loading.close()
        console.log(error)
        this.$message.warning('导出失败')
      })
    },
    downLoadTam() {
      window.location.href = 'https://qixindata.oss-cn-hangzhou.aliyuncs.com/data/%E6%9C%88%E6%8A%A5%E8%A1%A8%E5%A4%B4(1).xlsx'
    },
    importHandle(e) {
      let formData = new FormData()
      formData.append('file', e.target.files[0])
      formData.append('brand_id', this.currentBrand)
      this.$refs.refUpload.value = null;

      const loading = this.$loading({
          lock: true,
          text: '导入中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$api.importComplaintFunc(formData)
      .then(res => {
        // console.log(res)
        if(res.code == 0) {
          this.updateDataTag = !this.updateDataTag
          this.utils.notificationInfo('提示','导入成功','success')
        } else {
          this.$message.warning(res.msg)
        }
        loading.close()
      })
      .catch(error => {
        loading.close()
        console.log(error)
      })
    },
    submitForm() {
      this.$refs.editDom.validate((valid) => {
        if(valid) {
          this.btnLoading = true
          let paramObj = {
            brand_id: this.currentBrand
          }
          Object.keys(this.formData).forEach(key => {
            if(this.formData[key]) paramObj[key] = this.formData[key]
          })

          if(this.isAdd) {
            this.$api.addComplaintRecord(paramObj)
            .then(res => {
              // console.log(res)
              this.btnLoading = false
              if(res.code == 0) {
                this.editInfoVis = false
                this.updateDataTag = !this.updateDataTag
                this.utils.notificationInfo('提示','已添加','success')
              }
            })
            .catch(error => {
              console.log(error)
              this.btnLoading = false
              this.$message.error('添加失败')
            })
          } else {
            paramObj.id = this.mId
            this.modifyFunc(paramObj, function() {
              this.mId = ''
              this.editInfoVis = false
              this.utils.notificationInfo('提示',`修改完成`,'success')
              this.btnLoading = false
            }.bind(this))
          }
          
        }
      })
    },
    resetForm() {
      Object.keys(this.formData).forEach(key => {
          this.formData[key] = ''
      })
    },
    // 添加信息
    openAddPop(data){
        this.editInfoVis = true
        if(data) {
          this.isAdd = false
          Object.keys(this.formData).forEach(key => {
              this.formData[key] = data[key]
          })
          this.mId = data.id
        } else {
          this.isAdd = true
          this.resetForm()
        }
    },
    open(row) {
      window.open(row.goods_url)
    },
    
    // 删除操作
    delHandle(id){
        this.$confirm('确认删除吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
          this.$api.deleteComplaintRecord({id})
          .then(res => {
              if(res.code == 0) {
                  this.utils.notificationInfo('提示', '已删除', 'success')
                  this.updateDataTag = !this.updateDataTag
              }
          })
          .catch(error => {
              console.log(error)
              this.utils.notificationInfo('提示', '操作失败', 'warning')
          })
        }) 
    },

    // 查询
    searchClick () {
        if(this.timer != null) clearTimeout(this.timer)
        // 防抖
        this.timer = setTimeout(()=>{
            this.updateDataTag = !this.updateDataTag
        },500)
    },
    // 重置查询
    resetSearch () {
        Object.keys(this.queryForm).forEach(key => {
            this.queryForm[key] = ''
        })
        this.updateDataTag = !this.updateDataTag
    },
    
    // 信息修改api
    modifyFunc(param, callBack){
      this.$api.modifyComplaintRecord(param)
      .then(res => {
          if(res.code == 0) {
              if(callBack) {
                  callBack()
              }
              this.updateDataTag = !this.updateDataTag
          }
      })
      .catch(error => {
          console.log(error)
          this.btnLoading = false
          this.utils.notificationInfo('提示', '操作失败', 'warning')
      })
    },
    
    dataQueryFunc(){
        let paramObj = {
          brand_id: this.currentBrand
        }
        Object.keys(this.queryForm).forEach(key => {
            if(this.queryForm[key]) {
              if(key == 'complaint_time') {
                paramObj[key] = this.queryForm[key].join(',')
              } else {
                paramObj[key] = this.queryForm[key]
              }
            }
        })

        return {
            apiName: 'getComplaintRecord',
            paramObj: paramObj,
            success: function (response, callBack) {
              let records = response.data.list
              let arr = []
              records.forEach(item => {
                  Object.keys(item).forEach(key => {
                    if (item[key] == null || item[key] == undefined || item[key] == 'null' || item[key] == 'undefined') {
                        item[key] = ''
                    }
                  })
                  arr.push(item)
              })
              
              // 数据列表---更新
              this.dataList = arr

              // 数据总条数---更新
              this.totalCount = response.data.count
              // 回调函数
              if (callBack) {
                callBack()
              }
            }.bind(this),
            failed: function (error, callBack) {
              // 回调函数
              if (callBack) {
                callBack()
              }
            }
        }
    },
  },
}
</script>

<style lang="scss" scoped>
.complaints {

  .charts_area {
    margin-bottom: 3px;
    .charts_items {
      height: 350px;
      background: #fff;
    }
    .proport {
      min-width: 502px;
    }
  }


  // 投诉信息
  .cpt_info {
    text-align: left;
    span {
      color: #999;
    }
  }
}

.edit_icon {
  /deep/ i {
    font-size: 20px;
  }
}

// 导入按钮
.up_btn {
  position: relative;
  display: inline-block;
  .upload {
    width: 92px;
    height: 28px;
    position: absolute;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
}

</style>