// 客户文件
<template>
  <div class="c_files">
      <div class="title_box">
        <div class="title">
            <span>客户文件</span>
        </div>
        <div class="operation">
            <el-upload
            ref="upload"
            :action="$store.state.uploadUrl"
            :on-success="uploadSuccess"
            :limit="1"
            :show-file-list="false"
            >
                <el-button type="text">上传文件</el-button>
            </el-upload>
        </div>
      </div>
      <div class="view_list">
        <el-timeline>
            <el-timeline-item
            v-for="(item, index) in fileList"
            :key="index"
            color="#fff"
            icon="el-icon-folder-opened"
            >
                <svg class="icon-svg cust_icon" aria-hidden="true">
                    <use :xlink:href="`#icon-${item.text.split(',')[1].split('.')[1]}`"></use>
                </svg>
                <span @click="downloadHandle(item)" class="fontColor_theme little_hands">{{item.text.split(',')[1]}}</span>
                <div class="del_btn" @click="deleteHandle(item.id)"></div>
            </el-timeline-item>
        </el-timeline>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        currentBrand: { 
            type: Number 
        }
    },
    data() {
        return {
            fileList: []
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        downloadHandle(data) {
            window.location.href = data.text.split(',')[0]
        },
        uploadSuccess(response, file) {
            let paramObj = {
                brand_id: this.currentBrand,
                text: `${response.data},${file.name}`
            }
            this.$api.addCustomFile(paramObj)
            .then(res => {
                if(res.code == 0) {
                    this.getData()
                }
                this.$refs.upload.clearFiles()
            })
            .catch(error => {
                console.log(error)
                this.$message.error('上传失败')
                this.$refs.upload.clearFiles()
            })
        },
        getData() {
            const loading = this.$loading({
                target: '.c_files'
            })
            this.$api.getCustomFile({ brand_id: this.currentBrand })
            .then(res => {
                // console.log(res)
                loading.close()
                if(res.code == 0) {
                    this.fileList = [...res.data.list].reverse()
                }
            })
            .catch(error => {
                console.log(error)
                loading.close()
            })
        },
        deleteHandle(id) {
            this.$confirm('确认删除吗?','提醒',{
                confirmButtonText: '确定',
                cancelButtonText: '我再想想',
            })
            .then(()=> {
                this.$api.deleteCustomFile({id})
                .then(res => {
                    // console.log(res)
                    this.getData()
                })
                .catch(error => {
                    console.log(error)
                    this.$message.error('删除失败')
                })
            })
            .catch(()=> {})
        },
    }
}
</script>

<style lang="scss" scoped>
.c_files {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .title_box {
        display: flex;
        justify-content: space-between;
        height: 40px;
        padding: 0 20px;
        border-bottom: 1px solid #c5c5c9;

        .title {
            >span {
                line-height: 40px;
                font-size: 15px;
                font-weight: bold;
                padding-left: 10px;
                border-left: 4px solid #107CF6;
            }
        }
        
    }

    .view_list {
        padding: 20px;
        flex: 1;
        overflow-y: auto;

        // 自定义滚动条样式
        &::-webkit-scrollbar {
            width: 5px;
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: transparent;
        }
        &:hover::-webkit-scrollbar-thumb {
            background: hsla(0, 0%, 53%, 0.4);
        }
        &:hover::-webkit-scrollbar-track {
            background: hsla(0, 0%, 53%, 0.1);
        }

        // 时间线线条样式
        /deep/ .el-timeline-item__tail{
          border-left: 2px dotted #E4E7ED;
        }
        /deep/ .el-timeline-item__icon {
            color: #107CF6;
        }
        /deep/ .el-timeline-item__timestamp{
          // 更新时间样式
          font-size: 12px;
        }
        .el-timeline-item {
            position: relative;
            padding: 10px;
            padding-right: 20px;
            transition: all .2s;
            &:hover {
                .del_btn::after {
                    content: '×';
                }
                background: #f5f7fa;
            }
        }

        // 删除按钮
        .del_btn {
            font-size: 30px;
            color: #cc9a9a;
            cursor: pointer;
            position: absolute;
            top: -8px;
            right: 0;
            transition: all 0.2s;

            &:hover {
                color: red;
            }
            // &::after {
            //     content: '×'
            // }
        }
    }

    .cust_icon {
        margin-right: 10px;
    }
}
</style>