// 品牌投诉概况趋势图
<template>
    <div class="trend_box">
        <div class="query">
            <span>年份：</span>
            <el-select v-model="queryYear" size="mini" class="select" @change="yearChange">
                <el-option v-for="y in yearOptions" :key="y.value" :label="y.label" :value="y.value"></el-option>
            </el-select>
        </div>
        <div id="trend"></div>
    </div>
</template>

<script>
export default {
    props: {
        currentBrand: { 
            type: Number 
        },
        updateChart: { 
            type: Boolean 
        }
    },
    data() {
        return {
            chartObj: null,
            yearOptions: [],
            queryYear: new Date().getFullYear(),
            resizeTimer: null,
        }
    },
    mounted() {
        this.chartRender()

        // 初始化年份选项
        let date = new Date()
        for(let i = 0; i < 5; i++) {
            this.yearOptions.push({
                label: date.getFullYear() - i,
                value: date.getFullYear() - i,
            })
        }
        
        let that = this
        window.addEventListener("resize", () => {
            if (this.resizeTimer) clearTimeout(this.resizeTimer)
            this.resizeTimer = setTimeout(
                function() {
                    that.chartObj.resize()
                },
                50
            )
        })
    },
    watch: {
        currentBrand: function() {
            this.chartRender()
        },
        updateChart: function() {
            this.chartRender()
        }
    },
    methods: {
        yearChange() {
            this.chartRender()
        },
        chartRender() {
            let paramObj = {
                brand_id: this.currentBrand,
                year: this.queryYear
            }

            this.$api.complaintTrendChart(paramObj)
            .then(res => {
                // console.log(res)
                if(res.code == 0) {
                    let count = [], // 总量
                    y_count = [], // 成功量
                    s_rate = [] // 成功率

                    for(let i = 1; i< 13; i++ ){
                        res.data.list.forEach(item => {
                            if(item.month == i) {
                                count.push(item.count)
                                y_count.push(item.y_count)
                                s_rate.push(item.success_rate)
                            }
                        })
                    }

                    let colors = ['#5470C6', '#91CC75', '#EE6666']
                    let option = {
                        color: colors,
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross'
                            }
                        },
                        grid: {
                            top: '20%',
                            bottom: '10%'
                        },
                        // 工具栏
                        toolbox: {
                            feature: {
                                dataView: { show: true, readOnly: false },
                                saveAsImage: { show: true }
                            },
                            right: '1%'
                        },
                        legend: {
                            data: ['投诉量', '投诉成功量', '投诉成功率'],
                        },
                        xAxis: [
                            {
                                type: 'category',
                                axisTick: {
                                    alignWithLabel: true // 轴线和标签对齐
                                },
                                data: [ '1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月']
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                name: '数量（条）',
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: colors[0]
                                    }
                                },
                                axisLabel: {
                                    formatter: '{value}'
                                },
                            },
                            {
                                type: 'value',
                                name: '投诉成功率',
                                min: 0,
                                max: 100,
                                axisLine: {
                                    show: true,
                                    lineStyle: {
                                        color: colors[2]
                                    }
                                },
                                axisLabel: {
                                    formatter: '{value}%'
                                },
                                interval: 50
                            }
                        ],
                        series: [
                            {
                                name: '投诉量',
                                type: 'bar',
                                data: count
                            },
                            {
                                name: '投诉成功量',
                                type: 'bar',
                                data: y_count
                            },
                            {
                                name: '投诉成功率',
                                type: 'line',
                                yAxisIndex: 1,
                                data: s_rate
                            }
                        ]
                    }

                    this.chartObj = this.$echarts.init(
                        document.getElementById('trend')
                    )
                    this.chartObj.setOption(option)
                }
            })
            .catch(error => {
                console.log(error)
            })

            
        }
    }
}
</script>

<style scoped lang="scss">
.trend_box {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    .query {
        height: 30px;
        line-height: 30px;
        padding-left: 20px;

        .select {
            width: 100px;
        }
    }
    #trend {
        flex: 1;
    }
}

</style>