// 投诉类型占比
<template>
  <div class="proport_box">
      <div class="title">
          <h3>投诉理由分布情况</h3>
      </div>
      <div class="query">
          <div class="query_items">
              <span>投诉时间：</span>
            <el-date-picker
                v-model="queryForm.time"
                type="daterange"
                range-separator="-"
                size="mini"
                clearable
                unlink-panels
                @change="queryHandle"
                value-format="yyyy-MM-dd"
                class="date_range"
                >
            </el-date-picker>
          </div>
          <div class="query_items">
              <span>投诉结果：</span>
            <el-select
            v-model="queryForm.complaint_result"
            size="mini"
            clearable
            @change="queryHandle"
            class="select"
            >
                <el-option v-for="(el, index) in $co.complaintResOptions" :key="index" :label="el.label" :value="el.label"></el-option>
            </el-select>
          </div>
      </div>
      <div class="proport">
          <div class="progress">
              <div class="pro_items" v-for="(item, index) in dataList" :key="index">
                <span class="text">{{item.complaint_point}}</span>
                <el-progress :percentage="item.proportion" class="pro"></el-progress>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        currentBrand: { 
            type: Number 
        },
        updateChart: { 
            type: Boolean 
        }
    },
    data() {
        return {
            dataList: [],
            queryForm: {
                time: '',
                complaint_result: '',
            }
        }
    },
    watch: {
        currentBrand: function() {
            this.getData()
        },
        updateChart: function() {
            this.getData()
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        queryHandle() {
            this.getData()
        },
        getData() {
            const loading = this.$loading({
                target: '.proport_box'
            })
            let paramObj = {
                brand_id: this.currentBrand
            }

            Object.keys(this.queryForm).forEach(key => {
                if(this.queryForm[key]) {
                    if(key == 'time') {
                        paramObj[key] = this.queryForm[key].join(',')
                    } else {
                        paramObj[key] = this.queryForm[key]
                    }
                }
            })

            this.$api.complaintPPChart(paramObj)
            .then(res => {
                // console.log(res)
                loading.close()
                if(res.code == 0) {
                    let arr = []
                    res.data.list.forEach(item => {
                        arr.push({
                            complaint_point: item.complaint_point,
                            proportion: Number(item.proportion)
                        })
                    })

                    this.dataList = arr.sort(function(x,y) {
                        return y.proportion - x.proportion
                    })
                }
            })
            .catch(error => {
                loading.close()
                console.log(error)
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.proport_box {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px 0 10px;
    .title {
        height: 30px;
        padding-left: 10px;
        h3 {
            border-left: 4px solid #107CF6;
            font-size: 14px;
            padding-left: 5px;
        }
    }
    .query {
        height: 40px;
        line-height: 30px;
        padding-left: 20px;
        border-bottom: 1px solid #c5c5c9;
        display: flex;

        .query_items {
            margin-right: 10px;
            .date_range {
                width: 240px;
            }
            .select {
                width: 100px;
            }
        }
        
    }
    .proport {
        flex: 1;
        padding: 20px;
        overflow-y: auto;
        // 自定义滚动条样式
        &::-webkit-scrollbar {
            width: 5px;
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: transparent;
        }
        &:hover::-webkit-scrollbar-thumb {
            background: hsla(0, 0%, 53%, 0.4);
        }
        &:hover::-webkit-scrollbar-track {
            background: hsla(0, 0%, 53%, 0.1);
        }

        .pro_items {
            margin-bottom: 6px;
            .text {
                padding-left: 10px;
            }
            .pro {
                /deep/.el-progress__text {
                    font-size: 12px!important;
                }
            }
        }


    }
}
</style>