<!-- 任务详情 -->
<template>
  <div class="mainContentArea">
    <!-- 任务详情信息 -->
    <div class="t_detail">
      <el-page-header @back="goBack" content="任务详情"></el-page-header>

      <h3 class="b_name">任务名称：{{ taskInfo.task_name }}</h3>
      <div class="td_box">
        <div class="d_items">
          <p>
            任务类型：{{
              taskInfo.task_type == 1
                ? "关键词"
                : taskInfo.task_type == 2
                ? "掌柜ID"
                : "商品ID"
            }}
          </p>
        </div>
        <div class="d_items">
          <p>
            执行类型：{{ taskInfo.implement_type == "1" ? "即时" : "定时" }}
          </p>
        </div>
        <div class="d_items">
          <p>平台：{{ taskInfo.plt }}</p>
        </div>
        <div class="d_items">
          <p>排序方式：{{ taskInfo.sort == "0" ? "综合排序" : "销量排序" }}</p>
        </div>
        <div class="d_items">
          <p>数据版本：{{ taskInfo.tidy == "0" ? "简单版" : "详细版" }}</p>
        </div>
        <div class="d_items">
          <p>页数：{{ taskInfo.page }}</p>
        </div>
        <div class="d_items">
          <p>
            {{
              taskInfo.task_type == 1
                ? "关键词"
                : taskInfo.task_type == 2
                ? "掌柜ID"
                : "商品ID"
            }}：
            <el-popover placement="bottom-start" width="200" trigger="hover">
              <span v-for="(s, i) in taskInfo.search_key" :key="i"
                >{{ i == 0 ? null : "、" }}{{ s }}</span
              >
              <span
                type="text"
                slot="reference"
                class="fontColor_theme little_hands"
                >详情</span
              >
            </el-popover>
          </p>
        </div>
        <div class="d_items">
          <p>优先级：{{ taskInfo.level_zh }}</p>
        </div>
        <div class="d_items">
          <p>
            其他：{{ taskInfo.need_sku == "0" ? "不需要SKU" : "需要SKU" }}、{{
              taskInfo.del_search_key == "0"
                ? "不需要检索时删除品牌词"
                : "需要检索时删除品牌词"
            }}
          </p>
        </div>
      </div>

      <div class="btn_box">
        <el-button
          v-show="selectedList.length > 0"
          type="primary"
          @click="goDetail('batch')"
          size="small">
          批量查看
        </el-button>
      </div>
    </div>

    <!-- 任务执行信息 -->
    <tablePageComp
      :columnsList="columnsList"
      :dataList="dataList"
      :orderNumConfig="{}"
      :getDataConfig="dataQueryFunc()"
      :updateDataSignObj="{
        updateDataTag,
        resetPageNum,
      }"
      @setResetPageNum="(e) => (resetPageNum = e)"
      @multipleList="getMultipleList"
      :selectConfig="{
        visible: true,
        width: '54',
        workInTable: false,
        isFixed: true,
        operationVis: true,
      }"
      v-loading="loading">
      <!-- :totalCount="totalCount" -->

      <el-table-column slot="detail" align="center" width="150px">
        <template slot-scope="scope">
          <el-tooltip
            :content="scope.row.type === '0' ? '设为已处理' : '设为默认'"
            placement="top">
            <el-button
              type="text"
              :icon="
                scope.row.type === '0' ? 'el-icon-open' : 'el-icon-turn-off'
              "
              class="handle_icon"
              @click="modifyHandle(scope.row)"></el-button>
          </el-tooltip>

          <el-tooltip content="重新采集" placement="top">
            <el-button
              type="text"
              icon="el-icon-refresh"
              class="handle_icon"
              @click="setLastTimeHandle(scope.row)"
              :disabled="scope.row.status != '3'"></el-button>
          </el-tooltip>

          <el-button
            type="text"
            class="fontColor_theme"
            @click="goDetail(scope.row)"
            >详情</el-button
          >
        </template>
      </el-table-column>
    </tablePageComp>

    <el-dialog
      :visible.sync="setLastVis"
      title="设置任务失效时间"
      width="400px">
      <div style="text-align: center">
        <el-date-picker
          v-model="reloadForm.last_time"
          :clearable="false"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择失效日期时间">
        </el-date-picker>
      </div>
      <span slot="footer">
        <el-button @click="setLastVis = false" size="mini" :loading="btnLoading"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          @click="reloadHandle"
          :loading="btnLoading"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "taskDetail",
  props: {
    taskComParams: {
      required: true,
      type: Object,
    },
    // 当前品牌
    currentBrand: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      taskInfo: {},
      columnsList: [
        {
          label: "详情",
          slotName: "detail",
        },
        {
          label: "处理状态",
          prop: "type",
          formatter: (val) => {
            return val === "0" ? "默认" : "已处理";
          },
          width: 120,
        },
        {
          label: "任务ID",
          prop: "task_id",
        },
        {
          label: "平台",
          prop: "plt",
        },
        {
          label: "关键词",
          prop: "search_key",
        },
        {
          label: "页数",
          prop: "page",
        },
        {
          label: "进度",
          prop: "total_count",
        },
        {
          label: "开始时间",
          prop: "in_time",
          width: "150",
        },
        {
          label: "失效时间",
          prop: "last_time",
          width: "150",
        },
      ],
      dataList: [],
      updateDataTag: false,
      resetPageNum: true,
      // totalCount: 0,
      loading: false,

      allCurTaskId: [], // 当前任务所有已执行任务ID
      selectedList: [],

      setLastVis: false,
      reloadForm: {
        task_id: "",
        last_time: "", // 默认三天以后
      },
      btnLoading: false, // 按钮loading状态
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    getMultipleList(e) {
      this.selectedList = [];
      if (e == "selectAll") {
        this.selectedList = e;
      } else {
        e.forEach((item) => {
          this.selectedList.push(item.task_id);
        });
      }
    },
    // 设置已处理状态
    modifyHandle(row) {
      let paramObj = {
        task_id: row.task_id,
        type: row.type === "0" ? "1" : "0",
      };

      this.$api
        .reloadSingleTask(paramObj)
        .then((res) => {
          // console.log(res)
          if (res.code == 0) {
            this.resetPageNum = false;
            this.updateDataTag = !this.updateDataTag;
          } else {
            this.$message.warning(res.data.text);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$message.error("操作失败");
        });
    },
    setLastTimeHandle(row) {
      this.reloadForm = {
        task_id: row.task_id,
        last_time: this.utils.getStandardTime(
          new Date().getTime() + 3600 * 1000 * 24 * 3
        ),
      };
      this.setLastVis = true;
    },
    // 重新采集
    reloadHandle() {
      // this.$confirm(`确认重新采集这条任务吗?`, "", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // }).then(() => {
      let paramObj = {
        // task_id: row.task_id,
        ...this.reloadForm,
        status: "1",
        //   20231023 所属任务id
        id: this.taskComParams.task_id,
      };
      this.btnLoading = true;

      this.$api
        .reloadSingleTask(paramObj)
        .then((res) => {
          // console.log(res)
          if (res.code == 0) {
            this.resetPageNum = false;
            this.updateDataTag = !this.updateDataTag;
            this.utils.notificationInfo("提示", "已提交任务", "success");
            this.setLastVis = false;
          } else {
            this.$message.warning(res.data.text);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$message.error("操作失败");
        })
        .finally(() => {
          this.btnLoading = false;
        });
      // });
    },
    // 查看单次任务详情 || 查看所有已执行任务数据
    goDetail(row) {
      let single_task_id = "";
      if (row == "batch") {
        if (this.selectedList == "selectAll") {
          single_task_id = this.allCurTaskId.join(",");
        } else {
          single_task_id = this.selectedList.join(",");
        }
      } else {
        single_task_id = row.task_id;
      }
      // this.$router.push({
      //     path: 'single_task_info_list',
      //     query: {
      //         single_task_id,
      // currentBrand: JSON.parse(this.$route.query.currentBrand),
      //         task_name: this.taskInfo.task_name
      //     }
      // })
      this.$emit("toggleCom", {
        task_id: this.taskComParams.task_id,
        single_task_id,
        // currentBrand: this.currentBrand,
        task_name: this.taskInfo.task_name,
        target: "singleTaskList",
      });
    },
    goBack() {
      // window.history.go(-1)
      this.$emit("toggleCom", {
        target: "taskSubmission",
      });
    },
    getInfo() {
      let paramObj = {
        id: this.taskComParams.task_id,
        // id: this.$route.query.task_id
      };

      // 当前任务信息
      this.$api.taskSubList(paramObj).then((res) => {
        // console.log(res)
        if (res.code == 0) {
          let records = res.data.list[0];
          Object.keys(records).forEach((key) => {
            if (
              records[key] == null ||
              records[key] == undefined ||
              records[key] == "null" ||
              records[key] == "undefined"
            ) {
              records[key] = "";
            }
          });

          this.taskInfo = JSON.parse(JSON.stringify(records));
          delete this.taskInfo.pageNow;
          delete this.taskInfo.pageSize;
          delete this.taskInfo.xls_url;

          this.taskInfo.search_key = this.taskInfo.search_key.split("-;");
          this.taskInfo.level_zh = this.$co.taskLevelOptions.filter(
            (item) => item.value == this.taskInfo.level
          )[0].label;
          this.taskInfo.plt = this.taskInfo.plt.replace(/,/g, " ");
        }
      });
    },

    dataQueryFunc() {
      let paramObj = {
        id: this.taskComParams.task_id,
        // id: this.$route.query.task_id
      };

      return {
        apiName: "taskExecutionFunc",
        paramObj: paramObj,
        success: function (response, callBack) {
          let records = response.data.list;
          let arr = [];
          records.forEach((item) => {
            Object.keys(item).forEach((key) => {
              if (
                item[key] == null ||
                item[key] == undefined ||
                item[key] == "null" ||
                item[key] == "undefined"
              ) {
                item[key] = "";
              }
            });
            this.allCurTaskId.push(item.task_id);
            arr.push(item);
          });
          // console.log(this.allCurTaskId)
          // 数据列表---更新
          this.dataList = arr;

          // console.log(this.dataList,"Goods")
          // 数据总条数---更新
          // this.totalCount = response.data.count
          // 回调函数
          if (callBack) {
            callBack();
          }
        }.bind(this),
        failed: function (error, callBack) {
          // 回调函数
          if (callBack) {
            callBack();
          }
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.mainContentArea {
  .t_detail {
    margin: 10px 20px;
    padding: 20px;
    min-height: 100px;
    background: #fff;
    position: relative;

    .back {
      position: absolute;
      top: -5px;
      left: 20px;

      .el-button--text {
        /deep/ span {
          font-size: 16px;
        }
      }
    }

    .b_name {
      font-size: 14px;
      margin-top: 20px;
    }

    .td_box {
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;

      .d_items {
        width: 25%;
        padding-right: 20px;
        overflow: hidden;
        margin-bottom: 10px;
        color: #999;
      }
    }
  }
}
.handle_icon {
  margin-right: 10px;
  /deep/ i {
    font-size: 16px;
  }
}
</style>
