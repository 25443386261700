<template>
  <!-- 数据库管理组件 -->
    <el-dialog
    title="数据库管理"
    :visible.sync="databaseVis"
    width="420px"
    custom-class="popup_style"
    >
        <div class="db_area">
            <div class="add_btn">
                <el-button type="primary" plain size="mini" @click="databaseInnerVis = true">新建数据库</el-button>
            </div>
            <div class="db_list">
                <div class="db_list_item" v-for="item in databaseList" :key="item.id">
                    <div class="name">{{item.name}}</div>
                    <div class="del_btn" @click="delDBList(item.id)" v-if="item.name != '默认'"></div>
                </div>
            </div>
        </div>

        <el-dialog
        width="400px"
        title="新建数据库"
        :visible.sync="databaseInnerVis"
        append-to-body>
            <div class="db_name_area">
                <el-input v-model.trim="DB_name" placeholder="填入新数据库名称"></el-input>
            </div>
            
            <div class="btn_box" slot="footer">
                <el-button size="mini" type="primary" @click="subDB" :loading="btnLoading">确定</el-button>
                <el-button size="mini" @click="databaseInnerVis = false" :loading="btnLoading">取消</el-button>
            </div>
        </el-dialog>
    </el-dialog>
</template>

<script>
export default {
    props: {
        // 数据库列表
        databaseList: {
            type: Array,
            required: true
        },
        // 开启弹窗标记
        dbView: {
            type: Boolean,
        },
        currentBrand: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            // 数据库管理相关
            databaseVis: false,
            databaseInnerVis: false, // 添加数据库窗口
            // databaseList: [], 
            DB_name: '', // 数据库名称
            btnLoading: false
        }
    },
    watch:{
        dbView() {
            this.databaseVis = true
            this.DB_name = ''
        }
    },
    methods: {
        delDBList(id) {
            this.$confirm('将会删除此数据库中的所有数据，是否继续?','警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.deleteDatabase({id})
                .then(res => {
                    // console.log(res)
                    if(res.code == 0) {
                        this.utils.sessionRemoveStore('curDatabase')
                        this.$emit('getAgain')
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.$message.error('删除操作失败')
                })
            })
        },
        subDB() {
            if(this.DB_name ==='') {
                this.$message.warning('数据库名称未填')
                return
            } else 
            if(this.databaseList.some(v => v.name === this.DB_name)) {
                this.$message.warning('数据库名称重复')
                return
            }
            let paramObj = {
                brand_id: this.currentBrand,
                name: this.DB_name
            }
            this.$api.addDatabase(paramObj)
            .then(res => {
                // console.log(res)
                if(res.code === 0) {
                    this.databaseInnerVis = false
                    this.DB_name = ''
                    this.$emit('getAgain')
                }
            })
            .catch(error => {
                console.log(error)
                this.$message.error('新建失败')
            })
        },
    }
}
</script>

<style lang="scss" scoped>
// 数据库管理
.db_area {
    .add_btn {
        margin-top: -20px;
    }
    .db_list {
        height: 200px;
        margin-top: 10px;
        overflow-y: auto;
        border: 1px solid #eee;

        .db_list_item {
            padding: 10px 30px;
            display: flex;
            justify-content: space-around;

            &:hover {
                .del_btn::after {
                    content: '删除'
                }

                background: #eee;
            }

            .name {
                flex: 1;
            }
            .del_btn {
                cursor: pointer;
                color: red;
            }
        }
    }
}

.db_name_area {
    padding: 0 50px;
}
</style>