<!-- 控价表 -->
<template>
  <div class="mainContentArea">
      <div class="queryBox">
        <div class="database">
          <div class="cur_db">
            <span>
              当前控价表：
              <el-select size="mini" 
              v-model="currentCP.table_id" 
              @change="selectCurrentCP"
              class="cur_db_select"
              >
                <el-option
                v-for="item in controlPriceList"
                :key="item.id"
                :label="item.table_name"
                :value="item.table_id"
                ></el-option>
              </el-select>
            </span>
          </div>
          
        </div>
        <div class="query_row">
          <div class="scan_box">
            <span class="sp_scan">型号：</span>
            <el-input type="text" size="mini" @input="searchQuery" v-model="queryForm.sku_num" class="ip_scan"></el-input>
          </div>
        </div>
        <div class="btn_out_box">
          <el-button size="mini" class="btn_items" type="primary" @click="resetQuery">重置</el-button>
          <el-button class="btn_items" size="mini" @click="openAdd">添加</el-button>
          <div class="up_btn btn_items">
            <el-button size="mini">导入控价表</el-button>
            <input type="file" class="upload" ref="refUpload" @change="importHandle" />
          </div>
          <el-button size="mini" @click="manageHandle" class="btn_items">控价表管理</el-button>
          <el-button type="text" @click="download" class="btn_items">下载模板</el-button>
          <div style="float: right;">
            <el-button type="danger" @click="delHandle('brand_id')" size="mini">清空控价表</el-button>
          </div>
        </div>
      </div>

      <tablePageComp
      :columnsList="columnsList"
      :dataList="dataList"
      :orderNumConfig="{}"
      :getDataConfig="dataQueryFunc()"
      :totalCount="totalCount"
      :updateDataSignObj="{
        updateDataTag,
        resetPageNum
      }"
      @setResetPageNum="setRPN"
      :tableHeight="600"
      >
        <el-table-column
          slot="handle"
          align="center"
          label="操作栏"
          width="120"
          >
            <template slot-scope="scope">
              <div class="edit_icon">
                <el-button type="text" @click="delHandle(scope.row)" icon="el-icon-delete" class="fontColor_danger"></el-button>
              </div>
            </template>
          </el-table-column>
      </tablePageComp>

      <el-dialog
      :visible.sync="addVis"
      custom-class="popup_style"
      title="添加"
      width="400px"
      >
        <el-form >
          <el-form-item label="型号" class="formItem_dialogForm">
            <el-input
            class="element_df_input"
            v-model="addForm.sku_num"
            ></el-input>
          </el-form-item>
          <el-form-item label="价格" class="formItem_dialogForm">
            <el-input
            class="element_df_input"
            v-model="addForm.price_c"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="form_btn">
          <el-button size="small" type="primary" @click="submit">确定</el-button>
          <el-button size="small" @click="addVis = false">取消</el-button>
        </div>
      </el-dialog>

      <!-- 控价表管理 -->
      <el-dialog
      title="控价表管理"
      :visible.sync="manageVis"
      width="420px"
      custom-class="popup_style"
      >
          <div class="db_area">
              <div class="add_btn">
                  <el-button type="primary" plain size="mini" @click="manageInnerVis = true">新建控价表</el-button>
              </div>
              <div class="db_list">
                  <div class="db_list_item" v-for="item in controlPriceList" :key="item.id">
                      <div class="name">{{item.table_name}}</div>
                      <div class="del_btn" @click="delCPL(item)" v-if="item.table_name != '默认'"></div>
                  </div>
              </div>
          </div>

          <el-dialog
          width="400px"
          title="新建控价表"
          :visible.sync="manageInnerVis"
          append-to-body>
              <div class="db_name_area">
                  <el-input v-model.trim="table_name" placeholder="填入新控价表名称"></el-input>
              </div>
              
              <div class="btn_box" slot="footer">
                  <el-button size="mini" type="primary" @click="subCreate" :loading="btnLoading">确定</el-button>
                  <el-button size="mini" @click="manageInnerVis = false" :loading="btnLoading">取消</el-button>
              </div>
          </el-dialog>
      </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'priceControl',
  props:{
    // 当前品牌
    currentBrand: {
      type: Number
    },
    controlPriceList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      userId: '',
      columnsList: [
        {
          label: '操作',
          slotName: 'handle'
        },
        {
          label:'型号',
          prop: 'sku_num'
        },
        {
          label:'价格',
          prop: 'price_c'
        }
      ],
      resetPageNum: false,// 是否在更新时重置页码和页面
      dataList: [],
      updateDataTag: false,
      totalCount: 0,
      addForm: {
        sku_num: '',
        price_c: ''
      },
      addVis: false,
      queryForm: {
        sku_num:''
      },
      btnLoading: false,
      // 控价表管理
      manageVis: false,
      manageInnerVis: false,
      table_name: '',// 新建控价表名称
      currentCP: {}, // 当前控价表
    }
  },
  watch:{
    'currentBrand':function(val) {
        this.setCurrentCP()
        this.updateDataTag = !this.updateDataTag
    },
    'controlPriceList': function(val) {
      this.setCurrentCP()
      this.updateDataTag = !this.updateDataTag
    }
  },
  created() {
    this.userId = this.utils.sessionGetStore('userId')
    this.setCurrentCP()
  },
  methods: {
    // 控价表管理
    manageHandle() {
      this.manageVis = true
      this.table_name = ''
    },
    subCreate() {
      if(this.table_name === '') {
        this.$message.warning('控价表名称未填')
        return
      }
      if(this.controlPriceList.some(v => v.table_name === this.table_name)) {
        this.$message.warning('名称重复')
        return
      }
      let paramObj = {
        user_id: this.userId,
        brand_id: this.currentBrand,
        table_name: this.table_name
      }
      this.btnLoading = true
      this.$api.createCPLibrary(paramObj)
      .then(res => {
        if(res.code === 0) {
          this.btnLoading = false
          this.manageInnerVis = false
          this.table_name = ''
          this.$emit('getNewCPL')
        }
      })
      .catch(error => {
        console.log(error)
        this.btnLoading = false
        this.$message.error('新建失败')
      })
    },
    delCPL(data) {
      this.$confirm('将会删除此控价表中的所有数据，是否继续?','警告', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let paramObj = {
                  table_id: data.table_id,
                  brand_id: data.brand_id,
                  user_id: this.userId,
                }
                this.$api.deleteCPLibrary(paramObj)
                .then(res => {
                    // console.log(res)
                    if(res.code == 0) {
                        this.utils.sessionRemoveStore('currentCP')
                        this.$emit('getNewCPL')
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.$message.error('删除操作失败')
                })
            })
    },
    // 切换控价表
    selectCurrentCP() {
      this.currentCP.table_name = this.controlPriceList.filter(item => item.table_id == this.currentCP.table_id )[0].table_name
      this.utils.sessionSetStore('currentCP', this.currentCP)
      this.updateDataTag = !this.updateDataTag
    },
    // 设置当前控价表
    setCurrentCP() {
      let cp = JSON.parse(this.utils.sessionGetStore('currentCP'))
      if(cp) {
        this.currentCP = cp
      } else {
        this.currentCP = {...this.controlPriceList.filter(item => item.table_name == '默认')[0]}
      }
    },
    // 查询
    searchQuery() {
      this.utils.debounce(function() {
        this.updateDataTag = !this.updateDataTag
      }.bind(this), 500)
    },
    resetQuery() {
      Object.keys(this.queryForm).forEach(key => {
        this.queryForm[key] = ''
      })
      this.updateDataTag = !this.updateDataTag
    },
    delHandle(row) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '我再想想',
        type: 'warning'
      }).then(() => {
        let paramObj = {}
        if(row == 'brand_id') {
          // 清空
          paramObj.brand_id = this.currentBrand
        } else {
          // 当个删除
          paramObj.id = row.id
        }
        paramObj.table_id =  this.currentCP.table_id,// 当前控价表
        
        this.$api.deleteControlPriceFunc(paramObj)
        .then(res => {
          if(res.code == 0) {
            this.updateDataTag = !this.updateDataTag
            this.utils.notificationInfo('提示','已删除','success')
          }
        })
        .catch(error => {
          console.log(error)
          this.$message.error('操作失败')
        })
      })
    },
    // 添加
    openAdd() {
      this.addVis = true
      Object.keys(this.addForm).forEach(key => {
        this.addForm[key] = ''
      })
    },
    submit() {
      if(this.addForm.sku_num == '') {
        this.$message.warning('型号未填')
        return
      }
      if(this.addForm.price_c == '') {
        this.$message.warning('价格未填')
        return
      }
      let paramObj = {
        brand_id: this.currentBrand,
        sku_num: this.addForm.sku_num,
        price_c: this.addForm.price_c,
        table_id: this.currentCP.table_id,// 当前控价表
      }
      this.$api.addControlPriceFunc(paramObj)
      .then(res => {
        if(res.code == 0) {
          this.addVis = false
          this.updateDataTag = !this.updateDataTag
          this.utils.notificationInfo('提示','添加成功','success')
        } else {
          this.$message.warning( res.msg )
        }
      })
      .catch(error => {
        console.log(error)
        this.$message.error('操作失败')
      })
    },
    // 导入
    importHandle(e) {
      let file = e.target.files[0]
      let formData = new FormData()
      formData.append('file', file)
      formData.append('brand_id', this.currentBrand)
      formData.append('table_id', this.currentCP.table_id)
      this.$refs.refUpload.value = null;

      const loading = this.$loading({
          lock: true,
          text: '导入中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
      })
      this.$api.importControlPriceFunc(formData)
      .then(res => {
        // console.log(res)
        if(res.code == 0) {
          this.updateDataTag = !this.updateDataTag
          this.utils.notificationInfo('提示','导入成功','success')
        } else {
          this.$message.warning(res.msg)
        }
        loading.close()
      })
      .catch(error => {
        loading.close()
        console.log(error)
      })

    },
    // 下载模板
    download() {
      window.location.href = 'https://bwgalileo.oss-cn-hangzhou.aliyuncs.com/mb/%E5%AE%A2%E6%88%B7%E6%8F%90%E4%BE%9B%E6%8E%A7%E4%BB%B7%E8%A1%A8%E6%A8%A1%E6%9D%BF.xlsx'
    },

    // 更新状态
    setRPN(e) {
      this.resetPageNum = e
    },
    dataQueryFunc() {
      let paramObj = {
        brand_id: this.currentBrand,
        table_id: this.currentCP.table_id
      }

      Object.keys(this.queryForm).forEach(key => {
        if(this.queryForm[key]) {
          paramObj[key] = this.queryForm[key]
        }
      })

      return {
          apiName:'getControlPriceFunc',
          paramObj,
          success: function(res, callBack) {
              // console.log(res)
              let records = res.data.list
              let arr = []
              records.forEach(item => {
                  Object.keys(item).forEach(key => {
                      if(item[key] == null 
                      || item[key] == undefined 
                      || item[key] == 'null'
                      || item[key] == 'undefined') {
                          item[key] = ''
                      }
                  })

                  arr.push(item)
              })
              this.dataList = arr
              
              this.totalCount = res.data.count

              if(callBack) {
                  callBack()
              }

          }.bind(this),
          failed: function(error, callBack) {
              if(callBack) {
                  callBack()
              }
          }
      }    
    }
  }
}
</script>

<style lang="scss" scoped>
.up_btn {
  position: relative;
  display: inline-block;
  .upload {
    width: 92px;
    height: 28px;
    position: absolute;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
}
.edit_icon {
  /deep/ i {
      font-size: 20px;
  }
}

// 控价表管理样式
.db_area {
    .add_btn {
        margin-top: -20px;
    }
    .db_list {
        height: 200px;
        margin-top: 10px;
        overflow-y: auto;
        border: 1px solid #eee;

        .db_list_item {
            padding: 10px 30px;
            display: flex;
            justify-content: space-around;

            &:hover {
                .del_btn::after {
                    content: '删除'
                }

                background: #eee;
            }

            .name {
                flex: 1;
            }
            .del_btn {
                cursor: pointer;
                color: red;
            }
        }
    }
}

.db_name_area {
    padding: 0 50px;
}

.database {
  // padding: 10px;
  display: flex;
  align-items: center;
  height: 40px;

  .cur_db {
    margin-right: 20px;
    >span {
      font-size: 16px;
      border-left: 4px solid #409EFF;
      padding-left: 10px;

      >span {
        font-weight: bold;
        font-size: 16px;
      }

      /deep/ .el-select {
        line-height: 25px;
      }

      .cur_db_select {
        /deep/ .el-input__inner {
          font-weight: bold;
          font-size: 16px;
          border: none;
          color: #000;
          // width: 120px;
        }
        /deep/ .el-input__suffix {
          display: none;
        }
      }
    }
  }
}
</style>