<!-- 白名单 -->
<template>
  <div class="mainContentArea">
    <div class="queryBox">
      <div class="query_row">
        <div class="scan_box">
          <span class="sp_scan">平台：</span>
          <!-- <el-input type="text" size="mini" @input="searchQuery" v-model="queryForm.plt" class="ip_scan"></el-input> -->
          <el-select
            v-model="queryForm.plt"
            size="mini"
            class="ip_scan"
            @change="searchQuery">
            <el-option
              v-for="l in $co.pltOptions"
              :key="l.value"
              :label="l.label"
              :value="l.label"></el-option>
          </el-select>
        </div>
      </div>
      <div class="btn_out_box">
        <el-button
          size="mini"
          class="btn_items"
          type="primary"
          @click="resetQuery"
          >重置</el-button
        >
        <el-button class="btn_items" size="mini" @click="openAdd"
          >添加</el-button
        >
        <div class="up_btn btn_items">
          <el-button size="mini">导入白名单</el-button>
          <input
            type="file"
            class="upload"
            ref="refUpload"
            @change="importHandle" />
        </div>
        <el-button
          class="btn_items"
          type="danger"
          plain
          size="mini"
          @click="emptyHandle"
          icon="el-icon-delete"
          >清空</el-button
        >
        <el-button type="text" @click="download" class="btn_items"
          >下载模板</el-button
        >
      </div>
    </div>

    <tablePageComp
      :columnsList="columnsList"
      :dataList="dataList"
      :orderNumConfig="{}"
      :getDataConfig="dataQueryFunc()"
      :totalCount="totalCount"
      :updateDataSignObj="{
        updateDataTag,
        resetPageNum,
      }"
      @setResetPageNum="setRPN">
      <el-table-column slot="handle" align="center" label="操作栏" width="120">
        <template slot-scope="scope">
          <div class="edit_icon">
            <el-button
              type="text"
              @click="delHandle(scope.row)"
              icon="el-icon-delete"
              class="fontColor_danger"></el-button>
          </div>
        </template>
      </el-table-column>
    </tablePageComp>

    <el-dialog
      :visible.sync="addVis"
      custom-class="popup_style"
      title="添加"
      width="400px">
      <el-form label-width="100px">
        <el-form-item label="平台" class="formItem_dialogForm">
          <!-- <el-input
            class="element_df_input"
            v-model="addForm.plt"
            ></el-input> -->
          <el-select v-model="addForm.plt" class="element_df_input">
            <el-option
              v-for="l in $co.pltOptions"
              :key="l.value"
              :label="l.label"
              :value="l.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="掌柜名称" class="formItem_dialogForm">
          <el-input
            class="element_df_input"
            v-model="addForm.shop_name"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="form_btn">
        <el-button size="small" type="primary" @click="submit">确定</el-button>
        <el-button size="small" @click="addVis = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "whiteList",
  props: {
    // 当前品牌
    currentBrand: {
      type: Number,
    },
  },
  data() {
    return {
      columnsList: [
        {
          label: "操作",
          slotName: "handle",
        },
        {
          label: "平台",
          prop: "plt",
        },
        {
          label: "掌柜名称",
          prop: "shop_name",
        },
      ],
      resetPageNum: false, // 是否在更新时重置页码和页面
      dataList: [],
      updateDataTag: false,
      totalCount: 0,
      addForm: {
        shop_name: "",
        plt: "",
      },
      addVis: false,
      queryForm: {
        plt: "",
      },
    };
  },
  watch: {
    currentBrand: function (val) {
      this.updateDataTag = !this.updateDataTag;
    },
  },
  methods: {
    emptyHandle() {
      this.$confirm("即将清空当前品牌的白名单，是否继续?", "提示", {
        lock: true,
        type: "error",
      }).then(() => {
        if (!this.currentBrand) {
          this.$message.error("当前品牌ID获取异常，请重试");
          return;
        }
        const loading = this.$loading({
          lock: true,
          text: "正在删除…",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let paramObj = {
          brand_id: this.currentBrand,
        };
        this.$api
          .emptyBrandWLFunc(paramObj)
          .then(() => {
            this.updateDataTag = !this.updateDataTag;
            this.utils.notificationInfo("消息", "已清空", "success");
          })
          .catch((error) => {
            console.log(error);
            this.$message.error("清空失败");
          })
          .finally(() => {
            loading.close();
          });
      });
    },
    // 查询
    searchQuery() {
      this.utils.debounce(
        function () {
          this.updateDataTag = !this.updateDataTag;
        }.bind(this),
        500
      );
    },
    resetQuery() {
      Object.keys(this.queryForm).forEach((key) => {
        this.queryForm[key] = "";
      });
      this.updateDataTag = !this.updateDataTag;
    },
    delHandle(row) {
      this.$confirm("是否确认删除此条信息?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "我再想想",
        type: "warning",
      }).then(() => {
        let paramObj = {
          id: row.id,
        };
        this.$api
          .deleteWhiteListFunc(paramObj)
          .then((res) => {
            if (res.code == 0) {
              this.updateDataTag = !this.updateDataTag;
              this.utils.notificationInfo("提示", "已删除", "success");
            }
          })
          .catch((error) => {
            console.log(error);
            this.$message.error("操作失败");
          });
      });
    },
    // 添加
    openAdd() {
      this.addVis = true;
      Object.keys(this.addForm).forEach((key) => {
        this.addForm[key] = "";
      });
    },
    submit() {
      if (this.addForm.plt == "") {
        this.$message.warning("平台未填");
        return;
      }
      if (this.addForm.shop_name == "") {
        this.$message.warning("店铺名称未填");
        return;
      }
      let paramObj = {
        brand_id: this.currentBrand,
        plt: this.addForm.plt,
        shop_name: this.addForm.shop_name,
      };
      this.$api
        .addWhiteListFunc(paramObj)
        .then((res) => {
          if (res.code == 0) {
            this.addVis = false;
            this.updateDataTag = !this.updateDataTag;
            this.utils.notificationInfo("提示", "添加成功", "success");
          } else {
            this.$message.warning(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$message.error("操作失败");
        });
    },
    // 导入
    importHandle(e) {
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append("file", file);
      formData.append("brand_id", this.currentBrand);
      this.$refs.refUpload.value = null;

      const loading = this.$loading({
        lock: true,
        text: "导入中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.$api
        .importWhiteListFunc(formData)
        .then((res) => {
          // console.log(res)
          if (res.code == 0) {
            this.updateDataTag = !this.updateDataTag;
            this.utils.notificationInfo("提示", "导入成功", "success");
          } else {
            this.$message.warning(res.msg);
          }
          loading.close();
        })
        .catch((error) => {
          loading.close();
          console.log(error);
        });
    },
    // 下载模板
    download() {
      window.location.href =
        "https://bwgalileo.oss-cn-hangzhou.aliyuncs.com/mb/%E7%99%BD%E5%90%8D%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx";
    },

    // 更新状态
    setRPN(e) {
      this.resetPageNum = e;
    },
    dataQueryFunc() {
      let paramObj = {
        brand_id: this.currentBrand,
      };

      Object.keys(this.queryForm).forEach((key) => {
        if (this.queryForm[key]) {
          paramObj[key] = this.queryForm[key];
        }
      });

      return {
        apiName: "getWhiteListFunc",
        paramObj,
        success: function (res, callBack) {
          // console.log(res)
          let records = res.data.list;
          let arr = [];
          records.forEach((item) => {
            Object.keys(item).forEach((key) => {
              if (
                item[key] == null ||
                item[key] == undefined ||
                item[key] == "null" ||
                item[key] == "undefined"
              ) {
                item[key] = "";
              }
            });

            arr.push(item);
          });
          this.dataList = arr;

          this.totalCount = res.data.count;

          if (callBack) {
            callBack();
          }
        }.bind(this),
        failed: function (error, callBack) {
          if (callBack) {
            callBack();
          }
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.up_btn {
  position: relative;
  display: inline-block;
  .upload {
    width: 92px;
    height: 28px;
    position: absolute;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
}
.edit_icon {
  /deep/ i {
    font-size: 20px;
  }
}
</style>
