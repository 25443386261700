// 单次任务数据列表
<template>
  <div>
    <div class="mainContentArea" v-if="!emptyState">
      <div class="queryBox">
        <div class="btn_top_box">
          <el-page-header
            @back="goBack"
            :content="
              '任务ID：' +
              taskComParams.single_task_id +
              '； 任务名：' +
              taskComParams.task_name
            "></el-page-header>
          <!-- <el-page-header @back="goBack" :content="'任务ID：' + $route.query.single_task_id + '； 任务名：' + $route.query.task_name"></el-page-header> -->
        </div>
        <div class="query_row" v-show="!isFoldQuery">
          <div class="scan_box">
            <span class="sp_scan">平台：</span>
            <!-- <el-input v-model="queryForm.platform" @input="searchClick" class="ip_mini_scan" size="mini"></el-input> -->
            <el-select
              v-model="queryForm.platform"
              class="ip_scan"
              @change="searchClick"
              size="mini"
              clearable>
              <el-option
                v-for="l in $co.pltOptions"
                :key="l.value"
                :label="l.label"
                :value="l.label"></el-option>
            </el-select>
          </div>
          <div class="scan_box">
            <span class="sp_scan">关键词：</span>
            <el-input
              v-model="queryForm.search_key"
              @input="searchClick"
              class="ip_scan"
              size="mini"
              clearable></el-input>
          </div>
          <div class="scan_box">
            <span class="sp_scan">收录状态：</span>
            <el-select
              v-model="queryForm.is_income"
              class="ip_scan"
              @change="searchClick"
              size="mini"
              clearable>
              <el-option
                v-for="l in stateFilter"
                :key="l.value"
                :label="l.text"
                :value="l.value"></el-option>
            </el-select>
          </div>
          <div class="scan_box">
            <span class="sp_scan">数据属性：</span>
            <el-select
              v-model="queryForm.label"
              class="ip_scan"
              @change="searchClick"
              size="mini"
              clearable>
              <el-option
                v-for="l in dataAttrOptions"
                :key="l.value"
                :label="l.text"
                :value="l.value"></el-option>
            </el-select>
          </div>
          <div class="scan_box">
            <span class="sp_scan">店铺类型：</span>
            <el-select
              v-model="queryForm.is_white"
              size="mini"
              clearable
              class="ip_scan_sort"
              @change="searchClick">
              <el-option label="非白名单" value="0"></el-option>
              <el-option label="白名单" value="1"></el-option>
            </el-select>
          </div>
          <div class="scan_box">
            <span class="sp_scan">SKU编号：</span>
            <el-input
              v-model="queryForm.sku_id"
              class="ip_scan"
              size="mini"
              @input="searchClick"
              clearable></el-input>
          </div>
          <div class="scan_box">
            <span class="sp_scan">掌柜昵称：</span>
            <el-input
              v-model="queryForm.shopkeeper_nick"
              class="ip_scan"
              size="mini"
              @input="searchClick"
              clearable></el-input>
          </div>
          <div class="scan_box">
            <span class="sp_scan">店铺名称：</span>
            <el-input
              v-model="queryForm.shop_name"
              class="ip_scan"
              size="mini"
              @input="searchClick"
              clearable></el-input>
          </div>
          <div class="scan_box">
            <span class="sp_scan">商品编号：</span>
            <el-input
              v-model="queryForm.goods_id"
              class="ip_scan"
              size="mini"
              @input="searchClick"
              clearable></el-input>
          </div>
          <div class="scan_box">
            <span class="sp_scan">商品品牌：</span>
            <el-input
              v-model="queryForm.goods_brand"
              class="ip_scan"
              size="mini"
              @input="searchClick"
              clearable></el-input>
          </div>
          <div class="scan_box">
            <span class="sp_scan">商品名称：</span>
            <!-- <el-input v-model="queryForm.goods_title" class="ip_scan" size="mini" @input="searchClick" clearable></el-input> -->
            <el-select
              v-model.trim="queryForm.goods_title"
              size="mini"
              multiple
              filterable
              allow-create
              default-first-option
              class="ip_scan"
              @change="searchClick">
            </el-select>
          </div>
          <div class="scan_box">
            <span class="sp_scan">SKU名称：</span>
            <!-- <el-input v-model="queryForm.sku_att_class" class="ip_scan" size="mini" @input="searchClick" clearable></el-input> -->
            <el-select
              v-model.trim="queryForm.sku_att_class"
              size="mini"
              multiple
              filterable
              allow-create
              default-first-option
              class="ip_scan"
              @change="searchClick">
            </el-select>
          </div>
          <div class="scan_box">
            <span class="sp_scan">销量：</span>
            <el-select
              v-model="queryForm.month_sale_num"
              class="ip_scan"
              @change="searchClick"
              size="mini"
              clearable>
              <el-option label="销量为0" value="0"></el-option>
              <el-option label="销量大于0" value="1"></el-option>
            </el-select>
          </div>
        </div>

        <!-- 高级筛选区域 -->
        <div class="advanced_query" v-show="!isFoldQuery">
          <!-- <h3 class="ad_title">高级筛选</h3> -->
          <h3
            style="
              font-size: 16px;
              border-left: 4px solid #409eff;
              padding-left: 10px;
            ">
            高级筛选
          </h3>
          <div class="ad_query_row">
            <div class="ad_query_item">
              <span class="s_label">商品名称：</span>
              <el-select
                size="mini"
                v-model="queryForm.not_included"
                class="select"
                @change="searchClick">
                <el-option label="筛选包含所填内容" value="1"></el-option>
                <el-option label="筛选不包含所填内容" value="0"></el-option>
              </el-select>
            </div>
            <div class="ad_query_item">
              <span class="s_label">SKU页面价：</span>
              <div class="input_s">
                <el-input
                  size="mini"
                  v-model="queryForm.sku_now_price_small"
                  @input="searchClick"
                  placeholder="最小值"></el-input>
                <span class="mid">-</span>
                <el-input
                  size="mini"
                  v-model="queryForm.sku_now_price_large"
                  @input="searchClick"
                  placeholder="最大值"></el-input>
              </div>
            </div>
            <div class="ad_query_item">
              <span class="s_label">SKU预估到手价：</span>
              <div class="input_s">
                <el-input
                  size="mini"
                  v-model="queryForm.sku_hand_price_small"
                  @input="searchClick"
                  placeholder="最小值"></el-input>
                <span class="mid">-</span>
                <el-input
                  size="mini"
                  v-model="queryForm.sku_hand_price_large"
                  @input="searchClick"
                  placeholder="最大值"></el-input>
              </div>
            </div>
            <div class="ad_query_item">
              <span class="s_label">商品页面价：</span>
              <div class="input_s">
                <el-input
                  size="mini"
                  v-model="queryForm.goods_now_price_small"
                  @input="searchClick"
                  placeholder="最小值"></el-input>
                <span class="mid">-</span>
                <el-input
                  size="mini"
                  v-model="queryForm.goods_now_price_large"
                  @input="searchClick"
                  placeholder="最大值"></el-input>
              </div>
            </div>
            <div class="ad_query_item">
              <span class="s_label">商品预估到手价：</span>
              <div class="input_s">
                <el-input
                  size="mini"
                  v-model="queryForm.goods_hand_price_small"
                  @input="searchClick"
                  placeholder="最小值"></el-input>
                <span class="mid">-</span>
                <el-input
                  size="mini"
                  v-model="queryForm.goods_hand_price_large"
                  @input="searchClick"
                  placeholder="最大值"></el-input>
              </div>
            </div>
            <div class="ad_query_item">
              <span class="s_label">创建时间：</span>
              <el-date-picker
                size="mini"
                v-model="queryForm.time"
                class="date_range"
                type="daterange"
                range-separator="-"
                @change="searchClick"
                value-format="yyyy-MM-dd">
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="btn_out_box" v-show="!isFoldQuery">
          <el-button size="mini" @click="reset">重置查询</el-button>
          <el-button size="mini" @click="infoHandle('batch')"
            >批量操作</el-button
          >
          <el-button size="mini" @click="exportClickTag = !exportClickTag"
            >导出数据</el-button
          >
          <el-tooltip
            effect="dark"
            placement="top"
            content="将更新数据收录状态、店铺类型和数据属性">
            <el-button size="mini" @click="openMatch" type="success"
              >匹配数据</el-button
            >
          </el-tooltip>
          <el-button size="mini" type="primary" @click="beginInclud()"
            >收录</el-button
          >
          <el-button size="mini" type="primary" @click="OpenIrrelevantManage"
            >无关项管理</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="databaseVisSign = !databaseVisSign"
            >数据库管理</el-button
          >
          <el-button
            size="mini"
            @click="batchLink"
            type="warning"
            v-if="selectedList.length > 0"
            >批量查看链接</el-button
          >
        </div>
        <!-- 折叠按钮区域 -->
        <div class="fold_btn">
          <!-- 展开 -->
          <div
            class="f_open_area little_hands"
            @click="foldHandle"
            v-show="!isFoldQuery">
            <el-button
              type="text"
              icon="el-icon-arrow-up"
              class="btn_icon_s"></el-button>
            <p class="fold_content">收起筛选区域</p>
          </div>
          <!-- 折叠 -->
          <div class="f_fold_area" v-show="isFoldQuery">
            <h3>筛选区域</h3>
            <div class="f_f_content little_hands" @click="foldHandle">
              <p class="fold_content">展开筛选区域</p>
              <el-button
                type="text"
                icon="el-icon-arrow-down"
                class="btn_icon_s"></el-button>
            </div>
          </div>
        </div>
      </div>

      <tablePageComp
        :columnsList="columnsList"
        :dataList="dataList"
        :orderNumConfig="{}"
        :getDataConfig="dataQueryFunc()"
        :updateDataSignObj="{
          updateDataTag,
          resetPageNum,
        }"
        :totalCount="totalCount"
        :selectConfig="{
          visible: true,
          width: '54',
          workInTable: false,
          isFixed: true,
          operationVis: true,
        }"
        :pageSizeConfig="{
          pageSizeOption: [50, 100, 300, 500],
          pageSize: 50,
        }"
        @multipleList="getMultipleList"
        :tableHeight="600"
        @setResetPageNum="setRPN">
        <el-table-column
          slot="handle"
          align="center"
          label="操作栏"
          width="120">
          <template slot-scope="scope">
            <div class="edit_icon">
              <el-tooltip
                effect="dark"
                :content="scope.row.is_income == '1' ? '取消收录' : '收录'"
                placement="top-start">
                <el-button
                  type="text"
                  @click="cancelInclud(scope.row)"
                  class="fontColor_danger"
                  v-if="scope.row.is_income == '1'"
                  icon="el-icon-s-flag"></el-button>
                <el-button
                  type="text"
                  @click="beginInclud(scope.row.id)"
                  class="fontColor_info"
                  v-else-if="scope.row.is_income == '0'"
                  icon="el-icon-s-flag"></el-button>
              </el-tooltip>

              <el-button
                type="text"
                @click="infoHandle(scope.row)"
                icon="el-icon-edit-outline"></el-button>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          slot="goods_pic"
          align="center"
          label="商品信息"
          width="235">
          <template slot-scope="scope">
            <div class="g_info">
              <div class="g_pic">
                <el-image
                  :src="scope.row.goods_pic"
                  :preview-src-list="[scope.row.goods_pic]">
                  <div slot="error">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </div>
              <div class="table_stateBg g_right">
                <div style="text-align: left">ID：{{ scope.row.goods_id }}</div>
                <div>SKU：{{ scope.row.sku_id }}</div>
                <div class="bg_danger_light g_plt">
                  {{ scope.row.platform }}
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          slot="goods_name"
          align="center"
          label="商品名称"
          width="180">
          <template slot-scope="scope">
            <div class="g_title">
              <el-link
                :href="scope.row.goods_url_ult"
                type="primary"
                :underline="false"
                target="_blank"
                >{{ scope.row.goods_title }}</el-link
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column
          slot="state"
          align="center"
          label="收录状态"
          width="100"
          :filters="stateFilter"
          :filter-method="filterState">
          <template slot-scope="scope">
            <div v-if="scope.row.is_income == '0'" class="fontColor_danger">
              未收录
            </div>
            <div
              v-else-if="scope.row.is_income == '1'"
              class="fontColor_success">
              已收录
            </div>
          </template>
        </el-table-column>
        <el-table-column
          slot="label"
          align="center"
          label="数据属性"
          width="100"
          :filters="dataAttrOptions"
          :filter-method="filterLabel">
          <template slot-scope="scope">
            <div v-if="scope.row.label == '1'" class="fontColor_danger">
              无关项
            </div>
            <div v-else-if="scope.row.label == '0'">默认</div>
          </template>
        </el-table-column>
        <el-table-column
          slot="is_white"
          align="center"
          label="店铺类型"
          width="80">
          <template slot-scope="scope">
            <div v-if="scope.row.is_white == '0'" class="fontColor_danger">
              非白名单
            </div>
            <div
              v-else-if="scope.row.is_white == '1'"
              class="fontColor_success">
              白名单
            </div>
          </template>
        </el-table-column>

        <el-table-column
          slot="goods_coupon"
          align="center"
          label="商品优惠券"
          width="140">
          <template slot-scope="scope">
            <div v-if="scope.row.goods_coupon.length < 15">
              <div>{{ scope.row.goods_coupon }}</div>
            </div>
            <el-popover
              v-else
              placement="top"
              width="200"
              trigger="hover"
              :content="scope.row.goods_coupon">
              <div slot="reference">
                {{ scope.row.goods_coupon.substr(0, 15) + "..." }}
              </div>
            </el-popover>
          </template>
        </el-table-column>
      </tablePageComp>
    </div>

    <!-- 修改信息窗口 -->
    <el-dialog
      :title="isBatch ? '批量修改' : '修改信息'"
      :visible.sync="modifyVis"
      width="600px"
      custom-class="popup_style">
      <div class="operate_area">
        <div class="tips fontColor_danger" v-if="isBatch">
          Tips：批量修改信息时，将会覆盖掉所选数据原来的信息，请谨慎操作！
        </div>

        <div class="op_items">
          <label class="op_label" for="s_sum">型号打标：</label>
          <!-- <el-input
            v-model="modifyForm.sku_num" 
            placeholder="请填入型号"
            id="s_sum"
            class="input"
            >
            </el-input> -->
          <el-autocomplete
            v-model="modifyForm.sku_num"
            placeholder="请选择或填入型号"
            :fetch-suggestions="modelSearch"
            id="s_sum"
            class="input"
            clearable>
          </el-autocomplete>
        </div>

        <div class="op_items">
          <label class="op_label" for="is_low">数据属性：</label>
          <el-select
            v-model="modifyForm.label"
            id="is_low"
            class="input"
            clearable>
            <el-option label="默认" value="0"></el-option>
            <el-option label="无关项" value="1"></el-option>
          </el-select>
        </div>

        <div class="op_items radio_group_st">
          <!-- <label class="op_label">后置操作：</label> -->
          <el-radio-group
            v-model="isSyncIncluded"
            size="small"
            :disabled="modifyForm.label == '' && modifyForm.sku_num == ''">
            <el-radio-button :label="false">无额外操作</el-radio-button>
            <el-radio-button :label="true">同步进行收录</el-radio-button>
          </el-radio-group>
        </div>

        <div class="op_items" v-show="isSyncIncluded">
          <label class="op_label">目标数据库：</label>
          <el-select
            v-model="dbId"
            placeholder="选择要收录到的目标数据库"
            class="input">
            <el-option
              v-for="item in databaseList"
              :key="item.id"
              :value="item.id"
              :label="item.name"></el-option>
          </el-select>
        </div>
      </div>
      <div class="btn_box" slot="footer">
        <el-button
          size="small"
          type="primary"
          @click="subModify"
          :loading="btnLoading"
          >确定</el-button
        >
        <el-button size="small" @click="modifyVis = false" :loading="btnLoading"
          >取消</el-button
        >
      </div>
    </el-dialog>

    <!-- 导出信息对话框 -->
    <ExportComponent
      :exportDataAll="exportDataAll"
      :exportClickTag="exportClickTag"
      @getApiParams="getApiParamsFunc"
      apiName="exportSingleTaskFunc"
      :defaultSelected="$co.defaultSelectedArr"></ExportComponent>

    <!-- 无关项管理窗口 -->
    <el-drawer
      :visible.sync="imVis"
      direction="ltr"
      :show-close="false"
      :size="700"
      :withHeader="false"
      :destroy-on-close="true">
      <irrelevantManageComVue
        :currentBrand="currentBrand"></irrelevantManageComVue>
      <!-- <irrelevantManageComVue :currentBrand="Number($route.query.currentBrand)"></irrelevantManageComVue> -->
    </el-drawer>

    <!-- 数据库管理 -->
    <DatabaseCom
      :databaseList="databaseList"
      :currentBrand="currentBrand"
      :dbView="databaseVisSign"
      @getAgain="getDBList">
      <!-- :currentBrand="JSON.parse($route.query.currentBrand)" -->
    </DatabaseCom>

    <el-dialog width="400px" title="选择数据库" :visible.sync="selectDataVis">
      <div class="db_name_area">
        <el-select v-model="dbId" placeholder="选择目标数据库">
          <el-option
            v-for="item in databaseList"
            :key="item.id"
            :value="item.id"
            :label="item.name"></el-option>
        </el-select>
      </div>

      <div class="btn_box" slot="footer">
        <el-button size="mini" type="primary" @click="confirmInclud"
          >确定</el-button
        >
        <el-button size="mini" @click="selectDataVis = false">取消</el-button>
      </div>
    </el-dialog>

    <!-- 匹配选项 -->
    <el-dialog
      width="400px"
      title="匹配选项"
      custom-class="popup_style"
      :visible.sync="matchVis">
      <div style="text-align: center">
        <el-checkbox-group v-model="matchConfig">
          <el-checkbox label="收录状态"></el-checkbox>
          <el-checkbox label="无关项"></el-checkbox>
          <el-checkbox label="白名单"></el-checkbox>
        </el-checkbox-group>
      </div>
      <span slot="footer">
        <el-button size="mini" type="primary" @click="matchingHandle"
          >确定</el-button
        >
        <el-button size="mini" @click="matchVis = false">取消</el-button>
      </span>
    </el-dialog>

    <el-empty description="暂无内容" v-if="emptyState"></el-empty>
  </div>
</template>

<script>
import irrelevantManageComVue from "./components/irrelevantManageCom.vue";
import DatabaseCom from "@/components/tool/databaseCom.vue";
import { number } from "echarts";
export default {
  name: "singleTaskList",
  components: {
    irrelevantManageComVue,
    DatabaseCom,
  },
  props: {
    taskComParams: {
      type: Object,
      required: true,
    },
    currentBrand: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      emptyState: false, // 是否展示空状态
      // 表格内容相关
      columnsList: [
        {
          label: "操作",
          slotName: "handle",
        },
        {
          label: "商品信息",
          slotName: "goods_pic",
        },
        {
          label: "商品名称",
          slotName: "goods_name",
        },
        {
          label: "收录状态",
          slotName: "state",
        },
        {
          label: "数据属性",
          slotName: "label",
        },
        {
          label: "店铺类型",
          slotName: "is_white",
        },
        {
          label: "关键词",
          prop: "search_key",
          width: 180,
        },
        {
          label: "品牌",
          prop: "goods_brand",
          width: 150,
        },
        // {
        //     label: 'SKU编号',
        //     prop: 'sku_id',
        //     width: 120
        // },
        {
          label: "SKU名称",
          prop: "sku_att_class",
          width: 250,
        },
        {
          label: "SKU页面价",
          prop: "sku_now_price",
          width: 100,
        },
        {
          label: "SKU预估到手价",
          prop: "sku_hand_price",
          width: 120,
        },
        {
          label: "型号",
          prop: "sku_num",
          width: 120,
        },
        {
          label: "创建时间",
          prop: "create_at",
          width: 150,
        },
        {
          label: "商品页面价格",
          prop: "goods_now_price",
          width: 100,
        },
        {
          label: "商品预估到手价",
          prop: "goods_hand_price",
          width: 105,
        },
        {
          label: "店铺名称",
          prop: "shop_name",
          width: 100,
        },
        {
          label: "掌柜昵称",
          prop: "shopkeeper_nick",
          width: 100,
        },
        {
          label: "销量",
          prop: "month_sale_num",
          width: 100,
        },
        {
          label: "运费",
          prop: "shipping",
          width: 100,
        },
        // {
        //   label: "库存",
        //   prop: "inv_count",
        //   width: 100,
        // },
        {
          label: "SKU库存",
          prop: "sku_inv_count",
          width: 100,
        },
        {
          label: "发货地",
          prop: "place_of_delivery",
          width: 140,
        },
        {
          label: "商品优惠券",
          slotName: "goods_coupon",
        },
      ],
      dataList: [],
      updateDataTag: false,
      resetPageNum: true,
      totalCount: 0,

      queryForm: {
        platform: "", // 平台
        search_key: "",
        goods_title: "",
        is_income: "", // 收录状态
        label: "", // 数据属性

        sku_att_class: "", // sku名称
        sku_id: "", // sku编号
        shopkeeper_nick: "", // 掌柜昵称
        shop_name: "", // 店铺名称
        goods_id: "", // 商品编号
        goods_brand: "", // 商品品牌
        month_sale_num: "", // 销量:1- 销量不为 0, 0 - 销量为0

        not_included: "1", //筛选商品标题 是否包含0不包含,1包含

        // 价格区间
        sku_now_price_large: "", // sku页面价格
        sku_now_price_small: "",
        sku_hand_price_large: "", // sku预估到手价
        sku_hand_price_small: "",
        goods_now_price_large: "", // 商品页面价,
        goods_now_price_small: "",
        goods_hand_price_large: "", //商品预估到手价
        goods_hand_price_small: "",

        time: "", // 更新时间区间
      },
      stateFilter: [
        {
          text: "未收录",
          value: "0",
        },
        {
          text: "已收录",
          value: "1",
        },
      ],
      dataAttrOptions: [
        {
          text: "默认",
          value: "0",
        },
        {
          text: "无关项",
          value: "1",
        },
      ],
      selectedList: [], // 已选择内容
      btnLoading: false,
      modifyVis: false,
      isBatch: false,
      modifyForm: {
        label: "", // 数据属性
        sku_num: "", // 型号
        current_id: "", // 当前操作的数据id
      },
      isSyncIncluded: false, // 是否在修改后收录这些数据

      modelList: [
        // 示例
        // { 'value': 't-100' },
        // {'value':'t-333'},
      ], // 型号输入建议

      // 导出相关
      // 支持导出的所有字段
      exportDataAll: [
        {
          chinese: "数据属性",
          english: "label",
        },
        {
          chinese: "收录状态",
          english: "is_income",
        },
        {
          chinese: "商品编号",
          english: "goods_id",
        },
        {
          chinese: "商品链接",
          english: "goods_url",
        },
        {
          chinese: "商品品牌",
          english: "goods_brand",
        },
        {
          chinese: "店铺链接",
          english: "shop_url",
        },
        {
          chinese: "平台",
          english: "platform",
        },
        {
          chinese: "商品主图",
          english: "goods_pic",
        },

        {
          chinese: "商品名称",
          english: "goods_title",
        },
        {
          chinese: "SKU名称",
          english: "sku_att_class",
        },
        {
          chinese: "SKU编号",
          english: "sku_id",
        },
        {
          chinese: "型号",
          english: "sku_num",
        },
        {
          chinese: "商品页面价格",
          english: "goods_now_price",
        },
        {
          chinese: "商品预估到手价",
          english: "goods_hand_price",
        },
        {
          chinese: "SKU页面价",
          english: "sku_now_price",
        },
        {
          chinese: "SKU预估到手价",
          english: "sku_hand_price",
        },
        {
          chinese: "店铺名称",
          english: "shop_name",
        },
        {
          chinese: "掌柜昵称",
          english: "shopkeeper_nick",
        },
        {
          chinese: "销量",
          english: "month_sale_num",
        },
        {
          chinese: "创建时间",
          english: "create_at",
        },
        {
          chinese: "发货地",
          english: "place_of_delivery",
        },
        {
          chinese: "关键词",
          english: "search_key",
        },
        {
          chinese: "商品优惠券",
          english: "goods_coupon",
        },
        // {
        //   chinese: "库存",
        //   english: "inv_count",
        // },
        {
          chinese: "SKU库存",
          english: "sku_inv_count",
        },
        {
          chinese: "运费",
          english: "shipping",
        },
        // {
        //     chinese: '店铺注册公司名称',
        //     english: 'company_name',
        // },
        // {
        //     chinese: '店铺注册公司地址',
        //     english: 'company_addr',
        // },
      ],
      exportClickTag: false,
      imVis: false,

      // 数据库管理相关
      databaseVisSign: false,
      databaseList: [], // 数据库列表

      selectDataVis: false,
      dbId: "", // 当前选择数据库id

      isSingleInCluded: false, // 是否点击小红旗单条收录
      singleID: "", // 单条收录ID

      isFoldQuery: false, // 是否折叠筛选区域
      matchVis: false,
      matchConfig: [],
    };
  },
  created() {
    this.getModeList();

    this.getDBList();
    // 自动匹配一次
    // this.matchingHandle()
  },
  methods: {
    // 批量查看链接
    batchLink() {
      let arr = [];
      if (this.selectedList == "selectAll") {
        if (this.dataList.length >= 50) {
          this.$message.warning("链接量过大，已阻止新开窗口");
          return;
        } else {
          arr = [...this.dataList];
        }
      } else {
        arr = [...this.selectedList];
      }
      arr.forEach((item) => {
        window.open(item.goods_url_ult);
      });
    },
    // 折叠
    foldHandle() {
      this.isFoldQuery = !this.isFoldQuery;
    },
    // 单条取消收录
    cancelInclud(param) {
      this.$confirm("确认取消收录此链接吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "我再想想",
        type: "warning",
      }).then(() => {
        let paramObj = {
          goods_id: param.goods_id,
          platform: param.platform,
          sku_id: param.sku_id,
          brand_id: this.currentBrand,
        };

        // console.log(paramObj)
        this.$api
          .cancelInclub(paramObj)
          .then((res) => {
            if (res.code == 0) {
              this.updateDataTag = !this.updateDataTag;
              this.utils.notificationInfo("提示", "已取消收录", "success");
              this.resetPageNum = false;
            }
          })
          .catch((error) => {
            console.log(error);
            this.$message.warning("取消收录失败了");
          });
      });
    },
    // 获取数据库列表
    getDBList() {
      let paramObj = {
        brand_id: this.currentBrand,
        // brand_id: this.$route.query.currentBrand
      };
      this.$api
        .getDatabase(paramObj)
        .then((res) => {
          // console.log(res)
          if (res.code === 0) {
            this.databaseList = [...res.data.list];
            // this.utils.sessionSetStore('databaseOptions',[...res.data.list])
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 型号输入相关
    modelSearch(str, cb) {
      var results = str
        ? this.modelList.filter(this.createFilter(str))
        : this.modelList;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(str) {
      return (item) => {
        return item.value.toLowerCase().split(str).length > 1;
      };
    },
    // 获取控价表型号
    getModeList() {
      this.modelList = [];
      this.qModeList = [
        {
          label: "查看未打标",
          value: "未打标",
        },
      ];
      let paramObj = {
        brand_id: this.currentBrand,
        // brand_id: this.$route.query.currentBrand
      };
      this.$api.getControlPriceFunc(paramObj).then((res) => {
        // console.log(res)
        res.data.list.forEach((item) => {
          this.modelList.push({ value: item.sku_num });
        });
        // 型号筛选
        this.modelList.forEach((item) => {
          this.qModeList.push({
            value: item.value,
            label: item.value,
          });
        });
      });
    },
    // 开启无关项管理窗口
    OpenIrrelevantManage() {
      this.imVis = true;
    },
    openMatch() {
      this.matchVis = true;
      this.matchConfig = [];
      // this.matchConfig = ['收录状态','无关项','白名单']
    },
    // 匹配无关项
    matchingHandle() {
      let arr = [];
      if (this.matchConfig.length === 0) {
        this.$message.warning("请选择匹配内容");
        return;
      } else {
        if (this.matchConfig.includes("无关项")) arr.push("1");
        if (this.matchConfig.includes("收录状态")) arr.push("2");
        if (this.matchConfig.includes("白名单")) arr.push("3");
      }
      let paramObj = {
        brand_id: this.currentBrand,
        // brand_id: this.$route.query.currentBrand,
        type: arr.join(","),
      };

      const loading = this.$loading({
        lock: true,
        text: "匹配中",
      });
      this.$api
        .matchUnreleteFunc(paramObj)
        .then((res) => {
          // console.log(res)
          loading.close();
          this.matchVis = false;
          this.updateDataTag = !this.updateDataTag;
        })
        .catch((error) => {
          console.log(error);
          loading.close();
          this.$message.error("匹配失败！");
        });
    },
    // 导出参数处理
    getApiParamsFunc(callBack) {
      let paramObj = {
        task_id: this.taskComParams.single_task_id,
        // task_id: this.$route.query.single_task_id
      };
      Object.keys(this.queryForm).forEach((key) => {
        if (key == "time" || key == "goods_title" || key == "sku_att_class") {
          if (this.queryForm[key] && this.queryForm[key].length > 0) {
            paramObj[key] = this.queryForm[key].join(",");
          }
        } else {
          if (this.queryForm[key]) {
            if (key == "label") {
              paramObj[key + "_aa"] = this.queryForm[key];
            } else {
              paramObj[key] = this.queryForm[key];
            }
          }
        }
      });
      callBack(paramObj);
    },
    // 信息操作窗口
    infoHandle(row) {
      // console.log(row)
      // 初始化
      Object.keys(this.modifyForm).forEach((key) => {
        this.modifyForm[key] = "";
      });
      this.isSyncIncluded = false;
      this.dbId = "";

      // 批量操作
      if (row == "batch") {
        if (this.selectedList.length == 0) {
          this.$message.warning("还未选择任何信息");
          return;
        }
        this.modifyVis = true;
        this.isBatch = true;
      } else {
        // 单条操作
        this.modifyVis = true;
        this.isBatch = false;
        Object.keys(this.modifyForm).forEach((key) => {
          this.modifyForm[key] = row[key];
        });
        this.modifyForm.current_id = row.id;
      }
    },
    // 提交修改
    subModify() {
      if (this.modifyForm.label == "" && this.modifyForm.sku_num == "") {
        this.$message.warning("未填写内容");
        return;
      }
      this.btnLoading = true;
      let paramObj = {};
      // 单条修改：型号内容不做删除，如果所填为空，不做处理。其他字段如果为空，
      //          视为删除，将空值传给后端
      // 批量修改：字段为空时，不做处理
      if (this.isBatch) {
        // 批量操作id为多个数据id拼成的字符串
        Object.keys(this.modifyForm).forEach((key) => {
          if (this.modifyForm[key]) {
            paramObj[key] = this.modifyForm[key];
          }
        });

        if (this.selectedList == "selectAll") {
          // 勾选全部
          paramObj.task_id = this.taskComParams.single_task_id;
          // paramObj.task_id = this.$route.query.single_task_id
          // 加入查询条件
          Object.keys(this.queryForm).forEach((key) => {
            if (
              key == "time" ||
              key == "goods_title" ||
              key == "sku_att_class"
            ) {
              if (this.queryForm[key] && this.queryForm[key].length > 0) {
                paramObj[key] = this.queryForm[key].join(",");
              }
            } else if (this.queryForm[key]) {
              if (key == "label") {
                paramObj[key + "_aa"] = this.queryForm[key];
              } else {
                paramObj[key] = this.queryForm[key];
              }
            }
          });
        } else {
          let idArr = [];
          this.selectedList.forEach((item) => idArr.push(item.id));
          paramObj.id = idArr.join(",");
        }
      } else {
        // 单条修改
        Object.keys(this.modifyForm).forEach((key) => {
          if (key != "current_id") {
            paramObj[key] = this.modifyForm[key];
          }
        });
        paramObj.id = this.modifyForm.current_id;
      }
      // console.log(paramObj)
      this.$api
        .modifySingleTaskFunc(paramObj)
        .then((res) => {
          this.btnLoading = false;
          // console.log(res)
          if (res.code == 0) {
            // 修改完成后，如果需要收录
            if (this.isSyncIncluded) {
              if (this.dbId == "") {
                this.$message.warning("请选择要收录到的数据库");
                return;
              }
              const loading = this.$loading({
                lock: true,
                text: "正在收录，请稍后",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
              });
              let param = JSON.parse(JSON.stringify(paramObj));
              param.count_list = this.dbId; // 要收录到的目标数据库
              param.brand_id = this.currentBrand; // 添加品牌id - 2023.4.26
              this.$api
                .includedFunc(param)
                .then((res) => {
                  // console.log(res)
                  if (res.code == 0) {
                    this.updateDataTag = !this.updateDataTag;
                    this.utils.notificationInfo("提示", "已收录", "success");
                    // this.selectedList = [] // 表格刷新时已清空
                    // 初始化
                    this.resetPageNum = false;
                    this.selectDataVis = false;
                    this.isSingleInCluded = false;
                    this.isSyncIncluded = false;
                    this.singleID = "";
                    this.dbId = "";
                    this.modifyVis = false;
                  }
                  loading.close();
                })
                .catch((error) => {
                  console.log(error);
                  this.utils.notificationInfo("提示", "收录失败", "error");
                  loading.close();
                });
            } else {
              // 正常修改
              this.resetPageNum = false;
              this.updateDataTag = !this.updateDataTag;
              this.modifyVis = false;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.btnLoading = false;
          this.$message.error("操作失败");
        });
    },
    goBack() {
      // window.history.go(-1)
      this.$emit("toggleCom", {
        task_id: this.taskComParams.task_id,
        // currentBrand: this.currentBrand,
        target: "taskDetail",
      });
    },
    // 开始收录
    beginInclud(singleID) {
      if (singleID) {
        // 点击小红旗收录
        this.isSingleInCluded = true;
        this.singleID = singleID;
      } else {
        // 批量选择收录
        if (this.selectedList.length == 0) {
          this.$message.warning("还未选择任何信息！");
          return;
        }
      }

      // this.$confirm('确认将所选数据进行收录吗?', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      // }).then(() => {
      this.selectDataVis = true;
      // })
    },
    // 确认收录
    confirmInclud() {
      if (this.dbId == "") {
        this.$message.warning("请选择要收录到的数据库");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "正在收录，请稍后",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let paramObj = {
          count_list: this.dbId, // 要收录到的目标数据库
          // json: JSON.stringify(this.selectedList)
        },
        arr = [];
      if (this.isSingleInCluded) {
        // 点击小红旗收录
        paramObj.id = this.singleID;
      } else {
        // 批量选择收录
        // 勾选全部 传任务id 否则传id字符串
        if (this.selectedList == "selectAll") {
          paramObj.task_id = this.taskComParams.single_task_id;
          // paramObj.task_id = this.$route.query.single_task_id
          Object.keys(this.queryForm).forEach((key) => {
            if (
              key == "time" ||
              key == "goods_title" ||
              key == "sku_att_class"
            ) {
              if (this.queryForm[key] && this.queryForm[key].length > 0) {
                paramObj[key] = this.queryForm[key].join(",");
              }
            } else if (this.queryForm[key]) {
              if (key == "label") {
                paramObj[key + "_aa"] = this.queryForm[key];
              } else {
                paramObj[key] = this.queryForm[key];
              }
            }
          });
        } else {
          this.selectedList.forEach((item) => {
            arr.push(item.id);
          });
          paramObj.id = arr.join(",");
        }
      }

      paramObj.brand_id = this.currentBrand; // 添加品牌id - 2023.4.26
      this.$api
        .includedFunc(paramObj)
        .then((res) => {
          // console.log(res)
          if (res.code == 0) {
            this.updateDataTag = !this.updateDataTag;
            this.utils.notificationInfo("提示", "已收录", "success");
            // this.selectedList = [] // 表格刷新时已清空
            // 初始化
            this.resetPageNum = false;
            this.selectDataVis = false;
            this.isSingleInCluded = false;
            this.singleID = "";
            this.dbId = "";
          }
          loading.close();
        })
        .catch((error) => {
          console.log(error);
          this.utils.notificationInfo("提示", "收录失败", "error");
          loading.close();
        });
    },
    // 查询
    searchClick() {
      this.utils.debounce(
        function () {
          this.updateDataTag = !this.updateDataTag;
        }.bind(this),
        500
      );
    },
    // 重置查询
    reset() {
      Object.keys(this.queryForm).forEach((key) => {
        this.queryForm[key] = "";
      });
      this.queryForm.not_included = "1"; // 默认 1-包含
      this.updateDataTag = !this.updateDataTag;
    },
    // 筛选数据状态
    filterState(value, row) {
      return row.is_income === value;
    },
    // 筛选数据状态
    filterLabel(value, row) {
      return row.label === value;
    },
    // 获取表格批量选择结果
    getMultipleList(e) {
      // console.log(e)
      this.selectedList = e;
    },
    // 更新状态
    setRPN(e) {
      this.resetPageNum = e;
    },
    dataQueryFunc() {
      let id = this.taskComParams.single_task_id;
      // let id = this.$route.query.single_task_id
      if (!id) {
        this.emptyState = true;
        return;
      }
      let paramObj = {
        task_id: id,
      };
      Object.keys(this.queryForm).forEach((key) => {
        if (key == "time" || key == "goods_title" || key == "sku_att_class") {
          if (this.queryForm[key] && this.queryForm[key].length > 0) {
            paramObj[key] = this.queryForm[key].join(",");
          }
        } else {
          if (this.queryForm[key]) {
            paramObj[key] = this.queryForm[key];
          }
        }
      });

      return {
        apiName: "singleTaskInfoFunc",
        paramObj,
        success: function (res, callBack) {
          // console.log(res)
          let records = res.data.list;
          let arr = [];
          records.forEach((item) => {
            Object.keys(item).forEach((key) => {
              if (
                item[key] == null ||
                item[key] == undefined ||
                item[key] == "null" ||
                item[key] == "undefined"
              ) {
                item[key] = "";
              }
              // if(key == 'sku_num' && (item[key] == '' || item[key] == '00')) {
              //     // 没有型号显示 未打标
              //     item[key] = '未打标'
              // }
            });

            // 由于已下架商品取不到商品链接，自行进行商品链接设置
            item.goods_url_ult = this.utils.setGoodsUrl(item);

            arr.push(item);
          });
          this.dataList = arr;

          this.totalCount = res.data.count;

          if (callBack) {
            callBack();
          }
        }.bind(this),
        failed: function (error, callBack) {
          if (callBack) {
            callBack();
          }
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.g_info {
  display: flex;
  .g_pic {
    .el-image {
      width: 70px;
      height: 90px;

      i {
        font-size: 20px;
        line-height: 50px;
      }
    }
  }

  .g_right {
    padding-left: 10px;
    .g_plt {
      width: 50px;
      margin-top: 10px;
    }
  }
}
.g_title {
  text-align: left;
}

.edit_icon {
  /deep/ i {
    font-size: 20px;
  }
}

// 信息操作窗口
.operate_area {
  padding: 20px;
  text-align: center;

  .tips {
    margin-top: -30px;
    margin-bottom: 50px;
  }
  .op_items {
    min-height: 50px;
    margin-bottom: 10px;

    .op_label {
      display: inline-block;
      width: 100px;
      height: 100%;
      font-size: 14px;
      line-height: 50px;
      margin-right: 10px;
      text-align: right;
    }
    .input {
      width: 345px;
      line-height: 44px;
    }
  }

  .radio_group_st {
    text-align: left;
    padding-left: 61px;
    margin-top: 20px;
  }
}

.db_name_area {
  padding: 0 50px;
}

// 页头样式
.el-page-header {
  /deep/ .el-page-header__content {
    font-size: 14px;
  }
}
</style>
