var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"service"},[_c('div',{staticClass:"second-nav"},[_c('div',{staticClass:"brand"},[_c('div',{staticClass:"b_style"},[_c('span',[_vm._v("当前品牌：")]),_c('el-select',{staticClass:"b_select",attrs:{"size":"mini"},on:{"change":_vm.brandChange},model:{value:(_vm.currentBrand),callback:function ($$v) {_vm.currentBrand=$$v},expression:"currentBrand"}},_vm._l((_vm.brandOptions),function(b){return _c('el-option',{key:b.id,attrs:{"label":b.name + ' ( ' + b.id + ' )',"value":b.id}})}),1)],1)]),_c('ul',{staticClass:"navbar_box"},_vm._l((_vm.navData),function(item,index){return _c('div',{key:index},[_c('li',{class:'nav_items ' + (_vm.currentCom == item.path ? 'active_nav' : ''),on:{"click":function($event){return _vm.go(item.path)}}},[_c('span',{staticClass:"n_name"},[_vm._v(_vm._s(item.name))])])])}),0)]),_c('router-view'),_c('div',{staticClass:"component_box"},[_c('keep-alive',{attrs:{"exclude":['taskDetail', 'singleTaskList']}},[(
          (_vm.currentBrand && _vm.currentCom != 'dataList') ||
          (_vm.currentCom == 'dataList' &&
            _vm.currentBrand &&
            _vm.databaseOptions.length > 0) ||
          (_vm.currentCom == 'priceControl' &&
            _vm.currentBrand &&
            _vm.allControlPrice.length > 0)
        )?_c(_vm.currentCom,{tag:"component",attrs:{"currentBrand":_vm.currentBrand,"taskComParams":_vm.taskComParams,"currentBrandName":_vm.brandOptions.filter(function (item) { return item.id == _vm.currentBrand; })[0].name,"databaseOptions":_vm.databaseOptions,"controlPriceList":_vm.allControlPrice},on:{"getDatabaseAgain":_vm.getDBList,"getNewCPL":_vm.getCPLibrary,"toggleCom":_vm.toggle}}):_c('el-empty',{attrs:{"description":"暂无内容，请先创建品牌基本信息"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }