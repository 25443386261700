<!-- 无关项管理 -->
<template>
  <div class="im_style">
      <div class="query_box_irr">
          <div class="query_main">
              <div class="query_items">
                  <span class="s_label">平台：</span>
                  <!-- <el-input v-model="queryForm.platform" @input="searchClick" class="ip_scan" size="mini" clearable></el-input> -->
                  <el-select v-model="queryForm.platform" class="ip_scan" @change="searchClick" size="mini" clearable>
                    <el-option v-for="l in $co.pltOptions" :key="l.value" :label="l.label" :value="l.label"></el-option>
                  </el-select>
              </div>
              <div class="query_items">
                  <span class="s_label">商品ID：</span>
                  <el-input v-model="queryForm.goods_id" @input="searchClick" class="ip_scan" size="mini" clearable></el-input>
              </div>
          </div>
          <div class="btn_box">
              <el-button size="mini" @click="resetHandle" class="btn_items">重置查询</el-button>
              <div class="up_btn btn_items">
                <el-button size="mini">导入无关项</el-button>
                <input type="file" class="upload" ref="refUpload" @change="importHandle" />
              </div>
              <el-button size="mini" @click="exportHandle" class="btn_items" type="primary">导出</el-button>
              <el-button type="text" @click="download" class="btn_items">下载模板</el-button>
              <el-button type="danger" size="mini" style="float: right;" @click="emptyHandle">清空</el-button>
          </div>
          

      </div>
      <tablePageComp
      :columnsList="columnsList"
      :dataList="dataList"
      :orderNumConfig="{
        width: 60
      }"
      :getDataConfig="dataQueryFunc()"
      :updateDataSignObj="{
            updateDataTag,
            resetPageNum,
        }"
      :totalCount="totalCount"
      :tableHeight="tableHeight"
      :pageSizeConfig="{
        layoutConfig: 'prev, pager, next, sizes, total',
        pageSize: 50,
        pageSizeOption: [ 50, 80, 100, 120 ]
    }"
      >
      <!-- v-el-table-infinite-scroll="lazyLoad" -->

        <el-table-column
          slot="handle"
          align="center"
          label="操作栏"
          width="80"
          >
            <template slot-scope="scope">
              <div class="edit_icon">
                <el-button type="text" @click="delHandle(scope.row)" icon="el-icon-delete"></el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column
          slot="detail"
          align="center"
          width="100"
          >
            <template slot-scope="scope">
                <el-button type="text" @click="detailHandle(scope.row)" class="fontColor_theme">查看详情</el-button>
            </template>
          </el-table-column>
          <el-table-column
          slot="goods"
          align="center"
          label="商品信息"
          >
              <template slot-scope="scope">
                  <div class="g_info">
                    <div class="g_pic">
                      <el-image
                      :src="scope.row.goods_pic"
                      :preview-src-list="[scope.row.goods_pic]"
                      >
                          <div slot="error">
                              <i class="el-icon-picture-outline"></i>
                          </div>
                      </el-image>
                    </div>
                    <div class="table_stateBg g_right">
                      <div>商品ID：{{scope.row.goods_id}}</div>
                      <div>SKU-ID：{{scope.row.sku_id}}</div>
                      <div class="bg_danger_light g_plt">{{scope.row.platform}}</div>
                    </div>
                  </div>
              </template>
          </el-table-column>
      </tablePageComp>
  </div>
</template>

<script>
import elTableInfiniteScroll from 'el-table-infinite-scroll';
export default {
    directives: {
        'el-table-infinite-scroll': elTableInfiniteScroll
    },
    props: {
        currentBrand: { 
            type: Number 
        }
    },
    data() {
        return {
            columnsList: [
                {
                    label:'操作',
                    slotName: 'handle',
                },
                {
                    label: 'detail',
                    slotName: 'detail'
                },
                {
                    label: '信息',
                    slotName: 'goods'
                },
                // {
                //     label: '平台',
                //     prop: 'platform',
                // },
                // {
                //     label: '商品ID',
                //     prop: 'goods_id',
                // },
                // {
                //     label: 'SKU-ID',
                //     prop: 'sku_id',
                // },
            ],
            dataList: [],
            totalCount: 0,
            updateDataTag: false,
            resetPageNum: true,
            tableHeight: 0,
            queryForm: {
                goods_id:'',
                platform:''
            },
            // pageNow: 1,
            // isStopLoad: true,// 是否停止滚动 默认true，防止页面加载时自动触发一次
        }
    },
    mounted() {
        this.computeTH()
        window.addEventListener('resize', ()=> {
            this.utils.debounce(()=> {
                this.computeTH()
            }, 50)
        })
    },
    methods: {
        emptyHandle() {
            this.$confirm('确认清空无关项管理数据吗?')
            .then(() => {
                this.$api.emptyIrrelevant({brand_id: this.currentBrand})
                .then(res => {
                    this.updateDataTag = !this.updateDataTag
                })
                .catch(error => {
                    console.log(error)
                    this.$message.error('清空失败')
                })
            })
        },
        computeTH() {
            let bodyHeight = document.documentElement.clientHeight
            // 查询高度
            let queryH = 0
            const queryDom = document.getElementsByClassName('query_box_irr')
            if (queryDom.length) {
                queryH = queryDom[0].clientHeight
            }
            // 100-分页高度
            this.tableHeight = bodyHeight - queryH - 100
        },
        // 更新状态
        setRPN(e) {
            this.resetPageNum = e
        },
        lazyLoad() {
            if(!this.isStopLoad) {
                this.utils.debounce(function() {
                    this.pageNow++
                    this.updateDataTag = !this.updateDataTag
                }.bind(this), 300)
            }
        },
        detailHandle(param) {
            let plt = param.platform,
            targetUrl = ''
            switch(plt) {
                case '淘宝':
                    targetUrl = `https://item.taobao.com/item.htm?id=${param.goods_id}`
                    break;
                case '天猫':
                    targetUrl = `https://detail.tmall.com/item.htm?id=${param.goods_id}`
                    break;
                case '京东':
                    targetUrl = `https://item.jd.com/${param.goods_id}.html`
                    break;
                case '拼多多':
                    targetUrl = `https://mobile.yangkeduo.com/goods2.html?goods_id=${param.goods_id}`
                    break;
                case '1688':
                    targetUrl = `https://detail.1688.com/offer/${param.goods_id}.html`
                    break;
                case '当当':
                    targetUrl = `http://product.dangdang.com/${param.goods_id}.html`
                    break;
            }
            window.open(targetUrl, '_blank');
        },
        exportHandle() {
            let paramObj = {
                brand_id: this.currentBrand
            }
            const loading = this.$loading({
                lock: true,
                text: '导出中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7'
            })
            this.$api.exportIrrelevant(paramObj)
            .then(res => {
                // console.log(res)
                loading.close()
                if(res.code === 0) {
                    window.location.href = res.data.url
                } else {
                    this.$message.warning(res.msg)
                }
            })
            .catch(error => {
                console.log(error)
                loading.close()
                this.$message.error('导出失败！')
            })
        },
        resetHandle() {
            Object.keys(this.queryForm).forEach(key => {
                this.queryForm[key] = ''
            })
            // this.dataList = []
            // this.pageNow = 1
            // this.isStopLoad = false
            this.updateDataTag = !this.updateDataTag
        },
        searchClick() {
            this.utils.debounce(function() {
                // this.dataList = []
                // this.pageNow = 1
                // this.isStopLoad = false
                this.updateDataTag = !this.updateDataTag
            }.bind(this), 500)
        },
        // 下载模板
        download() {
            window.location.href = 'https://bwgalileo.oss-cn-hangzhou.aliyuncs.com/mb/%E6%97%A0%E5%85%B3%E9%A1%B9%E6%A8%A1%E6%9D%BF.xlsx'
        },
        importHandle(e) {
            let file = e.target.files[0]
            let formData = new FormData()
            formData.append('file', file)
            formData.append('brand_id', this.currentBrand)
            this.$refs.refUpload.value = null;

            const loading = this.$loading({
                lock: true,
                text: '导入中',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            })
            this.$api.importIrrelevant(formData)
            .then(res => {
                // console.log(res)
                if(res.code == 0) {
                    this.updateDataTag = !this.updateDataTag
                    this.utils.notificationInfo('提示','导入成功','success')
                } else {
                    this.$message.warning(res.msg)
                }
                loading.close()
            })
            .catch(error => {
                loading.close()
                console.log(error)
            })

        },
        delHandle(row) {
            this.$confirm('是否确认删除此条信息?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '我再想想',
                type: 'warning'
            }).then(() => {
                let paramObj = {
                    id: row.id
                }
                this.$api.delIrrelevant(paramObj)
                .then(res => {
                    if(res.code == 0) {
                        this.resetPageNum = false
                        this.updateDataTag = !this.updateDataTag
                        this.utils.notificationInfo('提示','已删除','success')
                    }
                })
                .catch(error => {
                    console.log(error)
                    this.$message.error('操作失败')
                })
            })
        },
        dataQueryFunc() {
            let paramObj = {
                brand_id: this.currentBrand,
                // pageSize: 15,
                // pageNow: this.pageNow,
            }
            Object.keys(this.queryForm).forEach(key => {
                if(this.queryForm[key]) {
                    paramObj[key] = this.queryForm[key]
                }
            })

            return {
                apiName: 'getIrrelevant',
                paramObj,
                success: function(res, callBack) {
                    // console.log(res)
                    let records = res.data.list
                    // 如果当前页条数小于15，说明到了最后一页，停止滚动
                    // 页码>1，避免为空时就弹出提醒
                    // if(res.data.list.length < 15) {
                    //     this.isStopLoad = true
                    //     if(this.pageNow > 1) {
                    //         this.$message.info('没有更多了')
                    //     }
                    // } else {
                    //     this.isStopLoad = false
                    // }
                    let arr = []
                    records.forEach(item => {
                        Object.keys(item).forEach(key => {
                            if(item[key] == null 
                            || item[key] == undefined 
                            || item[key] == 'null'
                            || item[key] == 'undefined') {
                                item[key] = ''
                            }
                        })

                        arr.push(item)
                    })
                    // this.dataList = this.dataList.concat(arr)
                    this.dataList = arr
                    this.totalCount = res.data.count
                    if(callBack) {
                        callBack()
                    }
                }.bind(this),
                failed: function(error, callBack) {
                    if(callBack) {
                        callBack()
                    }
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.g_info {
  display: flex;
  .g_pic {
    .el-image {
        width: 80px;
        height: 80px;

        i {
            font-size: 20px;
            line-height: 50px;
        }
    }
  }

  .g_right {
    padding-left: 10px;
    .g_plt {
      width: 50px;
      margin-top: 10px;
    }
  }
}
.im_style {
    .query_box_irr {
        padding: 20px 30px;
        
        .query_main {
            &::after {
                content: "";
                height: 0;
                line-height: 0;
                display: block;
                clear: both;
                visibility: hidden;
            }
            .query_items {
                float: left;
                margin-right: 20px;
                padding: 10px 0;

                .s_label {
                    float: left;
                    line-height: 28px;
                    margin-right: 5px;
                }
                .ip_scan {
                    float: left;
                    width: 140px;
                    margin-right: 20px;
                }
            }
        }

        .btn_box {
            .up_btn {
                position: relative;
                display: inline-block;
                .upload {
                    width: 92px;
                    height: 28px;
                    position: absolute;
                    left: 0;
                    opacity: 0;
                    cursor: pointer;
                }
            }
            .btn_items {
                margin-right: 10px;
            }
        }
    }
}
.edit_icon {
  /deep/ i {
      font-size: 20px;
      color: #F56C6C;
  }
}
</style>