<!-- 品牌信息 -->
<template>
  <div class="brand_info">
    <div class="brand_info_main">
      <el-row :gutter="20">
        <el-col :span="13">
          <div class="grid-content">
            <div class="b_info">
              <div class="b_info_t">
                <!-- <h3>品牌名：{{ brandInfo.brand_name }}</h3>
                <el-descriptions :column="3">
                  <el-descriptions-item label="公司名">{{
                    brandInfo.c_name
                  }}</el-descriptions-item>
                  <el-descriptions-item label="服务频次">{{
                    brandInfo.frequency
                  }}</el-descriptions-item>
                  <el-descriptions-item label="服务时间">{{
                    brandInfo.start_time
                  }}</el-descriptions-item>
                </el-descriptions> -->
                <div class="b_info_detail">公司名：{{ brandInfo.c_name }}</div>
                <div class="b_info_detail">
                  服务频次：{{ brandInfo.frequency }}
                </div>
                <div class="b_info_detail">
                  服务起止时间：{{ brandInfo.start_time }}
                </div>
              </div>
              <div class="b_line"></div>
              <div class="b_info_b">
                <div class="data_num_item">
                  <p class="dn_num">数据总量</p>
                  <p class="num">
                    <countTo
                      :startVal="0"
                      :endVal="dataNum"
                      :duration="1000"></countTo>
                    条
                  </p>
                </div>
                <div class="data_num_item">
                  <p class="dn_num">待更新总量</p>
                  <p class="num">
                    <countTo
                      :startVal="0"
                      :endVal="taskNum.all"
                      :duration="1000"></countTo>
                    条
                  </p>
                </div>
                <div class="data_num_item">
                  <p class="dn_num">淘宝总量</p>
                  <p class="num">
                    <countTo
                      :startVal="0"
                      :endVal="taskNum.tb"
                      :duration="1000"></countTo>
                    条
                  </p>
                </div>
                <div class="data_num_item">
                  <p class="dn_num">京东总量</p>
                  <p class="num">
                    <countTo
                      :startVal="0"
                      :endVal="taskNum.jd"
                      :duration="1000"></countTo>
                    条
                  </p>
                </div>
                <div class="data_num_item">
                  <p class="dn_num">拼多多总量</p>
                  <p class="num">
                    <countTo
                      :startVal="0"
                      :endVal="taskNum.pdd"
                      :duration="1000"></countTo>
                    条
                  </p>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="11">
          <div class="grid-content">
            <h2 class="col_title">异常链接概况</h2>
            <chartCompVue :currentBrand="currentBrand"></chartCompVue>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="bz">
            <brandRemarksVue :currentBrand="currentBrand"></brandRemarksVue>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="bz">
            <orderListVue
              :currentBrand="currentBrand"
              @orderDetail="toggleDom"></orderListVue>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="bz">
            <customerFilesVue :currentBrand="currentBrand"></customerFilesVue>
          </div>
        </el-col>
      </el-row>
      <!-- <el-row :gutter="20">
          <el-col :span="8"><div class="grid-content"></div></el-col>
          <el-col :span="8"><div class="grid-content"></div></el-col>
          <el-col :span="8"><div class="grid-content"></div></el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8"><div class="grid-content"></div></el-col>
          <el-col :span="8"><div class="grid-content"></div></el-col>
          <el-col :span="8"><div class="grid-content"></div></el-col>
        </el-row> -->
    </div>
  </div>
</template>

<script>
import chartCompVue from "./components/chartComp.vue";
import brandRemarksVue from "./components/brandRemarks.vue";
import orderListVue from "./components/orderList.vue";
import customerFilesVue from "./components/customerFiles.vue";
import countTo from "vue-count-to"; // 数字滚动

export default {
  name: "brandInfo",
  components: {
    chartCompVue,
    brandRemarksVue,
    orderListVue,
    customerFilesVue,
    countTo,
  },
  props: {
    currentBrand: {
      type: Number,
    },
  },
  data() {
    return {
      brandInfo: {
        brand_name: "",
        c_name: "",
        frequency: "",
        start_time: "",
      },
      dataNum: 0,
      taskNum: {
        all: 0,
        tb: 0,
        jd: 0,
        pdd: 0,
      },
      intervalObj: null,
    };
  },
  created() {
    this.getBrandInfo();
    this.getDataNum();
    this.getTaskQueue();

    if (!this.intervalObj) {
      this.intervalObj = setInterval(() => {
        this.getTaskQueue();
      }, 1000 * 60 * 5);
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalObj);
  },
  watch: {
    currentBrand: function (val) {
      this.getBrandInfo();
      this.getDataNum();
    },
  },
  methods: {
    toggleDom(e) {
      this.$emit("toggleCom", { ...e });
    },
    getBrandInfo() {
      let paramObj = {
        id: this.currentBrand,
      };
      this.$api
        .brandManageList(paramObj)
        .then((res) => {
          // console.log(res)
          if (res.code == 0) {
            let records = res.data.list[0];
            this.brandInfo = { ...records };
            this.brandInfo.start_time = this.brandInfo.start_time.replace(
              ",",
              " 至 "
            );
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDataNum() {
      let paramObj = {
        brand_id: this.currentBrand,
        pageNow: 1,
        pageSize: 1,
      };

      this.$api
        .getDataListFunc(paramObj)
        .then((res) => {
          // console.log(res)
          if (res.code == 0) {
            this.dataNum = res.data.count;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getTaskQueue() {
      this.$api
        .getQueueNum()
        .then((res) => {
          console.log(res);
          let records = res.data.List;
          records.forEach((item) => {
            switch (item.platform) {
              case "总数据":
                this.taskNum.all = item.count;
                break;
              case "拼多多":
                this.taskNum.pdd = item.count;
                break;
              case "京东":
                this.taskNum.jd = item.count;
                break;
              case "淘宝":
                this.taskNum.tb = item.count;
                break;
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.brand_info {
  // position: absolute;
  height: 100%;

  .brand_info_main {
    height: 100%;
    padding: 0 20px;

    .grid-content {
      background: #fff;
      height: 300px;
      margin-bottom: 15px;
      position: relative;
    }

    .bz {
      background: #fff;
      height: 400px;
      margin-bottom: 15px;
    }

    // 品牌信息
    .b_info {
      height: 100%;
      .b_info_t {
        padding: 20px 30px 10px;
        margin-bottom: 12px;

        > h3 {
          font-size: 20px;
        }
        .b_info_detail {
          font-size: 14px;
          margin: 10px 0;
        }
      }
      .b_line {
        margin: 0 20px;
        height: 1px;
        background: #c5c5c9;
      }
      .b_info_b {
        padding: 10px 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .data_num_item {
          text-align: center;
          .dn_num {
            font-size: 18px;
            font-weight: bold;
            margin: 10px 0 20px;
          }
          .num {
            font-size: 16px;
            > span {
              font-size: 16px;
              color: #1183fb;
              font-weight: bold;
            }
          }
        }
      }
    }

    // title样式
    .col_title {
      color: #fff;
      font-size: 14px;
      position: absolute;
      top: 0;
      left: 0;
      padding: 2px 50px 2px 20px;
      border-radius: 0 0 50px 0;
      background: -webkit-linear-gradient(270deg, #0a48d1, #1183fb);
      background: -o-linear-gradient(270deg, #0a48d1, #1183fb);
      background: -moz-linear-gradient(270deg, #0a48d1, #1183fb);
      background: linear-gradient(270deg, #0a48d1, #1183fb);
      letter-spacing: 1px;
    }
  }

  .empty_box {
    height: 100%;
  }
}
</style>
