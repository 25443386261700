<!-- 任务列表 -->
<template>
  <div class="mainContentArea">
    <!-- 查询区域 -->
    <div class="queryBox">
      <div class="query_row">
        <div class="scan_box">
          <span class="sp_scan">展示方式：</span>
          <el-select
            @change="searchClick"
            v-model="queryForm.is_hide"
            class="ip_scan"
            size="mini">
            <el-option label="默认" value="0"></el-option>
            <el-option label="显示已隐藏任务" value="1"></el-option>
          </el-select>
        </div>
        <!-- <div class="scan_box">
                <span class="sp_scan">数据版本：</span>
                <el-input 
                    @input="searchClick"
                    class="ip_scan" 
                    v-model="queryForm.searchname"
                    size="mini" 
                    placeholder="请输入关键词"
                ></el-input>
            </div> -->

        <div class="btn_box" style="">
          <el-button size="mini" type="primary" @click="resetSearch"
            >重置查询</el-button
          >
          <el-button size="mini" @click="openAddPop">新建采集任务</el-button>
          <!-- <el-tooltip effect="dark" placement="top" content="将更新品牌下数据收录状态和数据属性">
                    <el-button size="mini" @click="matchingHandle" type="success">匹配数据</el-button>
                </el-tooltip> -->
        </div>
      </div>
    </div>

    <tablePageComp
      :columnsList="columnsList"
      :dataList="dataList"
      :orderNumConfig="{}"
      :getDataConfig="dataQueryFunc()"
      :updateDataTag="updateDataTag"
      :totalCount="totalCount"
      :tableHeight="600">
      <el-table-column slot="task_name" align="center" label="任务名称">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="goDetail(scope.row)"
            class="fontColor_theme"
            >{{ scope.row.task_name }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column slot="status" align="center" label="任务状态">
        <template slot-scope="scope">
          <div v-if="scope.row.status == 0" class="fontColor_warning">
            进行中
          </div>
          <div v-else-if="scope.row.status == 1" class="fontColor_danger">
            已终止
          </div>
          <div v-else-if="scope.row.status == 2" class="fontColor_success">
            已完成{{
              scope.row.count_list_name
                ? `--已自动收录到${scope.row.count_list_name}数据库`
                : ""
            }}
          </div>
          <div v-else-if="scope.row.status == 4" class="fontColor_info">
            维护中
          </div>
        </template>
      </el-table-column>
      <el-table-column slot="task_type" align="center" label="任务类型">
        <template slot-scope="scope">
          <div
            :class="
              scope.row.implement_type == 1
                ? 'fontColor_theme'
                : 'fontColor_warning'
            ">
            {{ scope.row.implement_type == 1 ? "即时-" : "定时-" }}
            {{
              scope.row.task_type == 1
                ? "关键词采集"
                : scope.row.task_type == 2
                ? "掌柜ID采集"
                : "商品ID采集"
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        slot="priorityLevel"
        width="100"
        align="center"
        label="优先级">
        <template slot-scope="scope">
          <div v-if="scope.row.level == 1">默认</div>
          <div v-if="scope.row.level == 2" class="fontColor_theme">已加急</div>
        </template>
      </el-table-column>
      <!--   -->
      <el-table-column slot="handle" width="100" align="center" label="操作">
        <template slot-scope="scope">
          <el-tooltip content="重新采集" placement="top">
            <el-button
              type="text"
              icon="el-icon-refresh"
              class="handle_icon"
              @click="setLastTimeHandle(scope.row)"
              :disabled="scope.row.status == 0"></el-button>
          </el-tooltip>

          <!-- 即时任务或已终止任务，禁用按钮 -->
          <el-button
            type="text"
            class="handle_icon"
            icon="el-icon-switch-button"
            @click="modifyHandle(scope.row, '终止')"
            :disabled="scope.row.implement_type == 1 || scope.row.status == 1">
          </el-button>
          <!-- 隐藏显示操作 -->
          <el-tooltip
            :content="scope.row.is_hide == '0' ? '隐藏此任务' : '显示此任务'"
            placement="top">
            <el-button
              type="text"
              icon="el-icon-view"
              :class="
                scope.row.is_hide == '1' ? 'handle_icon i_color' : 'handle_icon'
              "
              @click="
                modifyHandle(
                  scope.row,
                  scope.row.is_hide == '0' ? '隐藏' : '显示'
                )
              "></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </tablePageComp>

    <el-dialog
      :visible.sync="setLastVis"
      title="设置任务失效时间"
      width="400px">
      <div style="text-align: center">
        <el-date-picker
          v-model="reloadForm.last_time"
          :clearable="false"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择失效日期时间">
        </el-date-picker>
      </div>
      <span slot="footer">
        <el-button @click="setLastVis = false" size="mini" :loading="btnLoading"
          >取消</el-button
        >
        <el-button
          type="primary"
          size="mini"
          @click="reloadHandle"
          :loading="btnLoading">
          确定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "taskSubmission",
  props: {
    // 当前品牌
    currentBrand: {
      type: Number,
    },
    currentBrandName: {
      type: String,
    },
  },
  data() {
    let n = this.utils.sessionGetStore("userName");
    return {
      userName: n,
      dataList: [],
      columnsList: [
        {
          label: "操作",
          slotName: "handle",
        },
        {
          label: "任务名称",
          slotName: "task_name",
        },
        {
          label: "任务状态",
          slotName: "status",
        },
        {
          label: "平台",
          prop: "plt",
          width: 120,
        },
        {
          label: "任务类型",
          slotName: "task_type",
        },
        {
          label: "数据版本",
          prop: "tidy",
          width: 150,
          formatter: (value) => {
            return value == 0 ? "简单版" : "详细版";
          },
        },
        // level 0 低  1 高
        //   {
        //       label:'优先级',
        //       slotName:'level'
        //   },
        {
          label: "创建人",
          //   prop:'username'
          prop: "user_id",
          width: "120",
        },
        {
          label: "创建时间",
          prop: "in_time",
          width: "150",
        },
        {
          label: "最近运行时间",
          prop: "up_time",
          width: "150",
        },
      ],
      updateDataTag: false,
      totalCount: 0,
      queryForm: {
        plt: "",
        searchname: "", // 关键词
        is_hide: "0", // 任务显示状态  0-显示 1-隐藏
      }, // 查询条件
      timer: null, // 延时器
      pltOptions: [
        {
          label: "淘宝",
          value: "淘宝",
        },
        {
          label: "京东",
          value: "京东",
        },
        {
          label: "拼多多",
          value: "拼多多",
        },
        {
          label: "1688",
          value: "1688",
        },
        {
          label: "当当",
          value: "当当",
        },
        {
          label: "小红书",
          value: "小红书",
        },
        {
          label: "苏宁易购",
          value: "苏宁易购",
        },
        {
          label: "国美",
          value: "国美",
        },
        {
          label: "lazada.sg",
          value: "lazada.sg",
        },
        {
          label: "lazada.my",
          value: "lazada.my",
        },
        {
          label: "微店",
          value: "微店",
        },
      ],
      btnLoading: false, // 按钮loading状态

      setLastVis: false,
      reloadForm: {
        id: "",
        last_time: "", // 默认三天以后
      },
    };
  },
  created() {},
  watch: {
    currentBrand: function (val) {
      this.updateDataTag = !this.updateDataTag;
    },
  },
  methods: {
    // 匹配无关项
    matchingHandle() {
      let paramObj = {
        brand_id: this.$route.query.currentBrand,
      };
      const loading = this.$loading({
        lock: true,
        text: "匹配中",
      });
      this.$api
        .matchUnreleteFunc(paramObj)
        .then((res) => {
          // console.log(res)
          loading.close();
          this.updateDataTag = !this.updateDataTag;
        })
        .catch((error) => {
          console.log(error);
          loading.close();
          this.$message.error("匹配失败！");
        });
    },
    setLastTimeHandle(row) {
      this.reloadForm = {
        id: row.id,
        last_time: this.utils.getStandardTime(
          new Date().getTime() + 3600 * 1000 * 24 * 3
        ),
      };
      this.setLastVis = true;
    },
    reloadHandle() {
      // this.$confirm(`确认重新采集这条任务吗?`, "", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // }).then(() => {
      let paramObj = {
        // id: row.id,
        ...this.reloadForm,
      };
      this.btnLoading = true;

      this.$api
        .reloadTaskFunc(paramObj)
        .then((res) => {
          // console.log(res)
          if (res.code == 0) {
            this.updateDataTag = !this.updateDataTag;
            this.utils.notificationInfo("提示", "已提交任务", "success");
            this.setLastVis = false;
          } else {
            this.message.warning(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
          this.$message.error("操作失败");
        })
        .finally(() => {
          this.btnLoading = false;
        });
      // });
    },
    //   跳转任务详情
    goDetail(row) {
      // this.$router.push({
      //     path: '/service_view/taskDetail',
      //     query: {
      //         task_id: row.id,
      //         currentBrand: this.currentBrand,
      //     }
      // })
      // 通过组件切换
      this.$emit("toggleCom", {
        task_id: row.id,
        // currentBrand: this.currentBrand,
        target: "taskDetail",
      });
    },

    // 删除操作
    delHandle(id) {
      this.$confirm("确认删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.delFunc(id);
      });
    },

    // 添加信息
    openAddPop() {
      this.$router.push({
        path: "/service_view/taskInfoEdit",
        query: {
          currentBrand: this.currentBrand,
          brand_name: this.currentBrandName,
        },
      });
    },
    // 修改信息
    modifyHandle(row, type) {
      this.$confirm(`确认${type}该条任务吗?`, "提示", {
        confirmButtonText: "我确定",
        cancelButtonText: "我再想想",
        type: "warning",
      }).then(() => {
        let paramObj = {
          id: row.id,
        };
        if (type == "终止") {
          paramObj.status = "1";
        } else {
          paramObj.is_hide = row.is_hide == "0" ? "1" : "0";
        }
        this.modifyFunc(
          paramObj,
          function () {
            this.utils.notificationInfo("提示", `已${type}该条任务`, "success");
          }.bind(this)
        );
      });
    },

    // 查询
    searchClick() {
      if (this.timer != null) clearTimeout(this.timer);
      // 防抖
      this.timer = setTimeout(() => {
        this.updateDataTag = !this.updateDataTag;
      }, 500);
    },
    // 重置查询
    resetSearch() {
      Object.keys(this.queryForm).forEach((key) => {
        this.queryForm[key] = "";
      });
      this.queryForm.is_hide = "0";
      this.updateDataTag = !this.updateDataTag;
    },

    // 信息修改api
    modifyFunc(param, callBack) {
      this.$api
        .modifyTaskSubList(param)
        .then((res) => {
          if (res.code == 0) {
            if (callBack) {
              callBack();
            }
            this.updateDataTag = !this.updateDataTag;
          }
        })
        .catch((error) => {
          console.log(error);
          this.utils.notificationInfo("提示", "操作失败", "warning");
        });
    },
    // 信息删除
    delFunc(id) {
      api
        .deleteOperateView(id)
        .then((res) => {
          if (res.code == 0) {
            this.utils.notificationInfo("提示", "操作成功", "success");
            this.updateDataTag = !this.updateDataTag;
          }
        })
        .catch((error) => {
          console.log(error);
          this.utils.notificationInfo("提示", "操作失败", "warning");
        });
    },
    dataQueryFunc() {
      let paramObj = {
        brand_id: this.currentBrand,
      };
      Object.keys(this.queryForm).forEach((key) => {
        if (this.queryForm[key]) {
          paramObj[key] = this.queryForm[key];
        }
      });

      return {
        apiName: "taskSubList",
        paramObj: paramObj,
        success: function (response, callBack) {
          let records = response.data.list;
          let arr = [];
          records.forEach((item) => {
            Object.keys(item).forEach((key) => {
              if (
                item[key] == null ||
                item[key] == undefined ||
                item[key] == "null" ||
                item[key] == "undefined"
              ) {
                item[key] = "";
              }
            });
            // 平台数据处理
            item.plt = item.plt.replace(/,/g, " ");
            arr.push(item);
          });

          // 数据列表---更新
          this.dataList = arr;

          // console.log(this.dataList,"Goods")
          // 数据总条数---更新
          this.totalCount = response.data.count;
          // 回调函数
          if (callBack) {
            callBack();
          }
        }.bind(this),
        failed: function (error, callBack) {
          // 回调函数
          if (callBack) {
            callBack();
          }
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-s {
  // display: inline-block;
  position: absolute;
  left: -25px;
  top: 20px;
  cursor: pointer;
}
.bz {
  > p {
    margin-bottom: 5px;
    > span {
      font-weight: 700;
    }
  }
}

.handle_icon {
  /deep/ i {
    font-size: 15px;
  }
}
.i_color {
  /deep/ i {
    color: #c0c4cc;
  }
}
</style>
