var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"im_style"},[_c('div',{staticClass:"query_box_irr"},[_c('div',{staticClass:"query_main"},[_c('div',{staticClass:"query_items"},[_c('span',{staticClass:"s_label"},[_vm._v("平台：")]),_c('el-select',{staticClass:"ip_scan",attrs:{"size":"mini","clearable":""},on:{"change":_vm.searchClick},model:{value:(_vm.queryForm.platform),callback:function ($$v) {_vm.$set(_vm.queryForm, "platform", $$v)},expression:"queryForm.platform"}},_vm._l((_vm.$co.pltOptions),function(l){return _c('el-option',{key:l.value,attrs:{"label":l.label,"value":l.label}})}),1)],1),_c('div',{staticClass:"query_items"},[_c('span',{staticClass:"s_label"},[_vm._v("商品ID：")]),_c('el-input',{staticClass:"ip_scan",attrs:{"size":"mini","clearable":""},on:{"input":_vm.searchClick},model:{value:(_vm.queryForm.goods_id),callback:function ($$v) {_vm.$set(_vm.queryForm, "goods_id", $$v)},expression:"queryForm.goods_id"}})],1)]),_c('div',{staticClass:"btn_box"},[_c('el-button',{staticClass:"btn_items",attrs:{"size":"mini"},on:{"click":_vm.resetHandle}},[_vm._v("重置查询")]),_c('div',{staticClass:"up_btn btn_items"},[_c('el-button',{attrs:{"size":"mini"}},[_vm._v("导入无关项")]),_c('input',{ref:"refUpload",staticClass:"upload",attrs:{"type":"file"},on:{"change":_vm.importHandle}})],1),_c('el-button',{staticClass:"btn_items",attrs:{"size":"mini","type":"primary"},on:{"click":_vm.exportHandle}},[_vm._v("导出")]),_c('el-button',{staticClass:"btn_items",attrs:{"type":"text"},on:{"click":_vm.download}},[_vm._v("下载模板")]),_c('el-button',{staticStyle:{"float":"right"},attrs:{"type":"danger","size":"mini"},on:{"click":_vm.emptyHandle}},[_vm._v("清空")])],1)]),_c('tablePageComp',{attrs:{"columnsList":_vm.columnsList,"dataList":_vm.dataList,"orderNumConfig":{
      width: 60
    },"getDataConfig":_vm.dataQueryFunc(),"updateDataSignObj":{
          updateDataTag: _vm.updateDataTag,
          resetPageNum: _vm.resetPageNum,
      },"totalCount":_vm.totalCount,"tableHeight":_vm.tableHeight,"pageSizeConfig":{
      layoutConfig: 'prev, pager, next, sizes, total',
      pageSize: 50,
      pageSizeOption: [ 50, 80, 100, 120 ]
  }}},[_c('el-table-column',{attrs:{"slot":"handle","align":"center","label":"操作栏","width":"80"},slot:"handle",scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"edit_icon"},[_c('el-button',{attrs:{"type":"text","icon":"el-icon-delete"},on:{"click":function($event){return _vm.delHandle(scope.row)}}})],1)]}}])}),_c('el-table-column',{attrs:{"slot":"detail","align":"center","width":"100"},slot:"detail",scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{staticClass:"fontColor_theme",attrs:{"type":"text"},on:{"click":function($event){return _vm.detailHandle(scope.row)}}},[_vm._v("查看详情")])]}}])}),_c('el-table-column',{attrs:{"slot":"goods","align":"center","label":"商品信息"},slot:"goods",scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"g_info"},[_c('div',{staticClass:"g_pic"},[_c('el-image',{attrs:{"src":scope.row.goods_pic,"preview-src-list":[scope.row.goods_pic]}},[_c('div',{attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture-outline"})])])],1),_c('div',{staticClass:"table_stateBg g_right"},[_c('div',[_vm._v("商品ID："+_vm._s(scope.row.goods_id))]),_c('div',[_vm._v("SKU-ID："+_vm._s(scope.row.sku_id))]),_c('div',{staticClass:"bg_danger_light g_plt"},[_vm._v(_vm._s(scope.row.platform))])])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }