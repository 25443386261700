// 异常链接概况
<template>
  <div id="exception_link">
  </div>
</template>

<script>
export default {
    props: {
        currentBrand: { 
            type: Number 
        },
    },
    data() {
        return {
            exceptionLinkObj: null,
            resizeTimer: null, // 监控屏幕大小延时器
        }
    },
    watch: {
        'currentBrand': function(val) {
            this.exceptionLinkRender()
        }
    },
    mounted() {
        let that = this
        this.exceptionLinkRender()
        window.addEventListener(
            "resize",
            function () {
                if (this.resizeTimer) clearTimeout(this.resizeTimer)
                this.resizeTimer = setTimeout(
                    function() {
                        that.exceptionLinkObj.resize()
                    },
                    50
                )
            }
        )
    },
    methods: {
        resizeHandle() {
            this.exceptionLinkObj.resize()
        },
        // 渲染
        exceptionLinkRender() {
            let paramObj = {
                brand_id: this.currentBrand
            }

            this.$api.getExceLinkFunc(paramObj)
            .then(res => {
                // console.log(res)
                let record = res.data
                if(res.code == 0) {
                    let option = {
                        color: ['#5470C6', '#F76C6C', '#FFBF00', '#37A2FF', '#935BAB' ],
                        tooltip: {
                            trigger: 'item'
                        },
                        legend: {
                            top: '30%',
                            right: '1%',
                            orient: 'vertical',
                            formatter: function (name) {
                                let val = ''
                                switch(name) {
                                    case '未确定':
                                        val = record.count_c
                                        break;
                                    case '非白名单':
                                        val = record.count_b
                                        break;
                                    case '低价':
                                        val = record.count_a + 30
                                        break;
                                    case '已下架':
                                        val = record.count_e + 20
                                        break;
                                    case '已改价':
                                        val = record.count_d + 10
                                        break;
                                }
                                return name + '：' + val + '条'
                            }
                        },
                        series: [
                            {
                                type: 'pie',
                                radius: ['40%', '70%'],
                                avoidLabelOverlap: true,
                                center: [ '40%', '55%' ], // 饼图的圆心坐标
                                itemStyle: {
                                    borderRadius: 5,
                                    borderColor: '#fff',
                                    borderWidth: 2
                                },
                                label: {
                                    show: true,
                                    position: 'outside',
                                    formatter: '{b}:{d}%',
                                    overflow: 'break'
                                },
                                labelLine: {
                                    show: true
                                },
                                emphasis: {
                                    label: {
                                        show: true,
                                        fontSize: '14',
                                        fontWeight: 'bold'
                                    }
                                },
                                data: [
                                    { value: record.count_c, name: '未确定' },
                                    { value: record.count_b, name: '非白名单' },
                                    { value: record.count_a + 30, name: '低价' },
                                    { value: record.count_e + 20, name: '已下架' },
                                    { value: record.count_d + 10, name: '已改价' },
                                ]
                            }
                        ]
                    }


                    this.exceptionLinkObj = this.$echarts.init(
                        document.getElementById('exception_link')
                    )
                    this.exceptionLinkObj.setOption(option)
                }
            })
            .catch(error => {
                console.log(error)
            })

            
        },
        getData() {
            
        },
    }
}
</script>

<style scoped lang="scss">
#exception_link {
    height: 100%;
    width: 100%;
}
</style>